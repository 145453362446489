import { FacebookLogin as MetaLogin } from '../../assets';

declare const FB: any;

export default function FacebookLoginBtn({
  afterSocialLoginSuccess,
}: {
  afterSocialLoginSuccess: ({ type, socialId, email }: { type: string; socialId: string; email: string }) => void;
}) {
  const handleFacebookLogin = (response: any) => {
    afterSocialLoginSuccess({
      type: 'facebook',
      socialId: response.id,
      email: response.email,
    });
  };
  const onClickLogin = () => {
    FB.login(
      function (response: any) {
        if (response.authResponse) {
          FB.api('/me?fields=name,email', function (response: any) {
            handleFacebookLogin(response);
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      { scope: 'public_profile,email' }
    );
  };
  return (
    <button className="flex items-center justify-center w-12 h-12 bg-blue-600 rounded-lg" onClick={onClickLogin}>
      <MetaLogin />
    </button>
  );
}
