import { useContext } from 'react';
import KakaoLogin from 'react-kakao-login';
import { useNavigate } from 'react-router-dom';
import { CancelSVG, ErrorCircle, KaKaoTalkLogin } from '../../assets';
import AuthAxios from '../../axios';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ModalContext } from '../../contexts/ModalContext';
import { setItem, USERINFO, USERTOKEN } from '../../utils/localStorage';
import IconButton from '../IconButton';
import { useTranslation } from 'react-i18next';
export default function KakaoLoginBtn({
  afterSocialLoginSuccess,
  dbinsurance = false,
}: {
  afterSocialLoginSuccess: (params: {
    type: string;
    socialId: string;
    email: string;
    ageRange?: string;
    birthday?: string;
    birthdayType?: 'LUNAR' | 'SOLAR';
    birthYear?: string;
    gender?: 'male' | 'female';
    name?: 'string';
    phoneNumber?: string;
  }) => void;
  dbinsurance?: boolean;
}) {
  const { setLoginSuccess } = useContext(AuthStateContext);
  const { handleModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <KakaoLogin
      token={`${process.env.REACT_APP_KAKAO_CLIENT_ID}`}
      onSuccess={(result: any) => {
        if (result?.profile?.kakao_account?.email) {
          afterSocialLoginSuccess({
            type: 'kakao',
            socialId: result.profile.id,
            email: result.profile.kakao_account.email,
            ageRange: result.profile.kakao_account.age_range,
            birthday: result.profile.kakao_account.birthday,
            birthdayType: result.profile.kakao_account.birthday_type,
            birthYear: result.profile.kakao_account.birthyear,
            gender: result.profile.kakao_account.gender,
            name: result.profile.kakao_account.name,
            phoneNumber: result.profile.kakao_account.phone_number,
          });
        } else {
          handleModal(
            <div className="relative w-[328px] h-[214px] md:w-[580px] md:h-[233px] rounded-lg bg-N10 text-N3 flex flex-col items-center p-[22px] text-center">
              <IconButton onClick={() => handleModal()} className="absolute top-1 right-1 w-[20px] h-[20px]">
                <CancelSVG />
              </IconButton>
              <ErrorCircle className="w-10 h-10" />
              <p className="mt-5 mb-2 text-lg font-bold text-N1">KaKao</p>
              <p className="mb-5 text-base text-N1">{t('kakaoErrorModal.emptyEmail')}</p>
              <div className="flex justify-between w-full gap-2 px-3">
                <button className="w-full h-12 rounded-lg bg-N5 text-N9" onClick={() => handleModal()}>
                  {t('kakaoErrorModal.close')}
                </button>
              </div>
            </div>
          );
        }
      }}
      onFail={(error) => {
        console.log(error);
      }}
      render={({ onClick }) => {
        const iconSize = dbinsurance ? '3rem' : '2rem';
        const bgSize = dbinsurance ? '4rem' : '3rem';
        return (
          <button
            onClick={onClick}
            className="flex items-center justify-center w-12 h-12 bg-yellow-400 rounded-lg"
            style={{ width: bgSize, height: bgSize }}
          >
            <KaKaoTalkLogin width={iconSize} height={iconSize} />
          </button>
        );
      }}
    />
  );
}
