import { NaverLogin } from '../../assets';
import LoginNaver from 'react-login-by-naver';

export default function NaverLoginBtn({ afterSocialLoginSuccess }) {
  const handleLogin = (naverUser) => {
    afterSocialLoginSuccess({
      type: 'naver',
      socialId: naverUser.id,
      email: naverUser.email,
    });
  };

  return (
    <LoginNaver
      clientId={process.env.REACT_APP_NAVER_CLIENT_ID}
      callbackUrl={process.env.REACT_APP_CALLBACK_URL}
      render={(props) => (
        <button onClick={props.onClick} className="flex items-center justify-center w-12 h-12 bg-green-500 rounded-lg ">
          <NaverLogin />
        </button>
      )}
      onSuccess={(result) => {
        handleLogin({ id: result.id, type: 'naver', email: result.email });
      }}
      onFailure={() => console.error('failed')}
    />
  );
}
