import { ClassAttributes, ButtonHTMLAttributes } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { JSX } from 'react/jsx-runtime';
import { AppleLogin } from '../../assets';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

export default function AppleLoginBtn({
  afterSocialLoginSuccess,
}: {
  afterSocialLoginSuccess: ({ type, socialId, email }: { type: string; socialId: string; email: string }) => void;
}) {
  const navigate = useNavigate();
  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: `${process.env.REACT_APP_APPLE_CLIENT_ID}`,
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: 'email name',
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI: `${process.env.REACT_APP_APPLE_REDIRECT_URL}`,
        /** State string that is returned with the apple response */
        state: 'state',
        /** Nonce */
        nonce: 'nonce',
        /** Uses popup auth instead of redirection */
        usePopup: true,
      }} // REQUIRED
      /** General props */
      uiType="dark"
      /** className */
      className="apple-auth-btn"
      /** Removes default style tag */
      noDefaultStyle={false}
      /** Allows to change the button's children, eg: for changing the button text */
      buttonExtraChildren="Continue with Apple"
      /** Extra controlling props */
      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
      onSuccess={(response: any) => {
        const { authorization } = response;
        const { id_token, code } = authorization;
        const decodedToken = jwtDecode<any>(id_token);
        const { sub, email } = decodedToken;
        afterSocialLoginSuccess({
          type: 'apple',
          socialId: sub,
          email: email,
        });
      }} // default = undefined
      /** Called upon signin error */
      onError={(error: any) => {
        console.error('애플 로그인 실패', error);
        navigate('/auth', { replace: true });
      }} // default = undefined
      /** Skips loading the apple script if true */
      skipScript={false} // default = undefined
      /** Apple image props */

      /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
      render={(
        props: JSX.IntrinsicAttributes & ClassAttributes<HTMLButtonElement> & ButtonHTMLAttributes<HTMLButtonElement>
      ) => (
        <button {...props} className="flex pt-[6.72px]  justify-center w-12 h-12 bg-black rounded-lg ">
          <AppleLogin />
        </button>
      )}
    />
  );
}
