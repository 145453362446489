import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContext } from '../../contexts/ModalContext';

/**
 * 팝업
 * 프로모션 쿠폰 지급 전 로그인 페이지
 */
export default function PromotionSignInModal() {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);

  return (
    <div className="p-4 text-N1 bg-N10 w-[328px] max-h">
      <div className="flex flex-col justify-center items-center gap-2 px-4">
        <h1>{t('promotion.afterSignup')}</h1>
        <h1>{t('promotion.getFreeCoupon')}</h1>
      </div>
      <footer className="flex justify-around w-full gap-2">
        <button className="w-full py-3 text-base rounded-lg text-N9 bg-N5 mt-4" onClick={() => handleModal('')}>
          {t('successModal.confirm')}
        </button>
      </footer>
    </div>
  );
}
