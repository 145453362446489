import { Route, Routes } from 'react-router-dom';
import loginImg from '../assets/images/auth_root_bg_img.png';
import AgrementPage from '../pages/Login/AgrementPage';
import BasicInfoPage from '../pages/Login/BasicInfoPage';
import ForgotPasswordPage from '../pages/Login/ForgotPasswordPage';
import LoginMainPage from '../pages/Login/LoginMainPage';
import ResetPasswordPage from '../pages/Login/ResetPasswordPage';
import SignUpPage from '../pages/Login/SignUpPage';
import BasicInfoForDBLifePage from '../pages/DBInsurance/BasicInfoForDBLifePage';

export default function te() {
  return (
    <div
      className="w-screen h-screen overflow-y-auto no-scrollbar"
      style={{
        backgroundImage: `url(${loginImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'left top',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Routes>
        <Route path="/" element={<LoginMainPage />} />
        <Route path="signWithEmail" element={<SignUpPage />} />
        <Route path="forgotPassword" element={<ForgotPasswordPage />} />
        <Route path="agrement" element={<AgrementPage />} />
        <Route path="basicInfo" element={<BasicInfoPage />} />
        <Route path="basicInfo-db" element={<BasicInfoForDBLifePage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
      </Routes>
    </div>
  );
}
