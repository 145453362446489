import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes } from 'react-router-dom';
import { AuthStateContext } from '../contexts/AuthContext';
import GiftPaymentPage from '../pages/Payment/GiftPaymentPage';
import PaymentPage from '../pages/Payment/PaymentPage';

export default function PaymentRoute() {
  const { t } = useTranslation();
  const { user } = useContext(AuthStateContext);

  if (!user) {
    return (
      <main className="flex flex-col items-center gap-2 mt-4 text-N10">
        <div>{t('web.loginInfoRequiredPage')}</div>
        <Link to="/auth">{t('web.login')}</Link>
      </main>
    );
  }
  return (
    <div className="flex w-full h-full">
      <Routes>
        <Route path="pay/subScribtion" element={<PaymentPage />} />
        <Route path="pay/gift" element={<GiftPaymentPage />} />
        <Route path="/*" element={<div>Not Found</div>} />
      </Routes>
    </div>
  );
}
