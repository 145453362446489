import { Navigate, Route, Routes } from 'react-router-dom';
import AppRoute from './routes/AppRoute';
import AuthRoute from './routes/AuthRoute';
import PaymentRoute from './routes/PaymentRoute';
import { AISurveyRoute } from './routes/AISurveyRoute';
import PromotionRoute from './routes/PromotionRoute';
import CategoryRoot from './routes/CategoryRoute';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

function App() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('description')} />
        <meta property="og:title" content={t('title')} />
        <meta property="og:description" content={t('description')} />
      </Helmet>
      <Routes>
        <Route path="/app/*" element={<AppRoute />} />
        <Route path="/category/*" element={<CategoryRoot />} />
        <Route path={'/auth/*'} element={<AuthRoute />} />
        <Route path={'/payment/*'} element={<PaymentRoute />} />
        <Route path="ai-survey/*" element={<AISurveyRoute />} />
        <Route path="promotion/*" element={<PromotionRoute />} />
        <Route path={'*'} element={<Navigate to="/app/*" />} />
      </Routes>
    </>
  );
}

export default App;
