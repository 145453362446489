import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';
import { FirebaseAnalyticsParam, ReactAppEnv } from '../../types';

const logFirebaseEvent = (eventName: string, params: FirebaseAnalyticsParam = {}): void => {
  params.environment = process.env.REACT_APP_ENV as ReactAppEnv;
  if (analytics) {
    logEvent(analytics, params.environment !== ReactAppEnv.PRODUCTION ? `test_${eventName}` : eventName, params);
  }
};

export { logFirebaseEvent };
