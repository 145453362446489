import { Suspense, useContext } from 'react';
import { Navigate as NavigateRoute, Route, Routes, useLocation } from 'react-router-dom';
import { LOGOSVG } from '../assets';
import { MusicPlayerStateContext } from '../contexts/MusicContext';
import loginImg from '../assets/images/unsplash_KonWFWUaAuk.jpg';
import OnePageCategoryRoot from '../pages/OnePageCategory/Root';
import OnePageAudioPlayer from '../components/audioPlayerForOnePage/Player.v2';

export default function CategoryRoot() {
  const { currentPlayMusic, currentTheme } = useContext(MusicPlayerStateContext);
  const location = useLocation();
  const noHeader = location.pathname.startsWith('/app/membership');
  return (
    <div className="relative flex w-full h-full items-center justify-center">
      <img
        src={currentTheme?.artwork ? `${process.env.REACT_APP_IMG_ENDPOINT}/${currentTheme.artwork}` : loginImg}
        style={{ zIndex: -1 }}
        alt="background_theme"
        className="absolute top-0 left-0 object-cover w-full h-full"
      />
      <main className="relative w-full h-full md:w-[calc(100%-240px)] overflow-hidden">
        {!noHeader && (
          <header className="px-4 flex justify-between items-center top-0 w-full h-[60px] gap-2 md:hidden ">
            <div className="flex items-center gap-2">
              <LOGOSVG className="w-[93px]" style={{ fill: 'white' }} />
            </div>
          </header>
        )}
        <main
          className={`${
            noHeader
              ? 'h-full md:h-[calc(100%-80px)] overscroll-y-auto no-scrollbar'
              : currentPlayMusic
                ? 'h-[calc(100%-140px)] md:h-[calc(100%-80px)] overscroll-y-auto no-scrollbar'
                : 'h-[calc(100%-60px)] md:h-full overscroll-y-auto no-scrollbar '
          }`}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/:id/*" element={<OnePageCategoryRoot />} />
              <Route path="*" element={<NavigateRoute to="/sleep" replace />} />
            </Routes>
          </Suspense>
        </main>
        <OnePageAudioPlayer />
      </main>
    </div>
  );
}
