import { useNavigate } from 'react-router-dom';
import { ArrowLeftSVG } from '../../assets';
import IconButton from '../IconButton';

export function BackButton({ title }: { title?: string }) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-2">
      <IconButton onClick={() => navigate(-1)}>
        <ArrowLeftSVG />
      </IconButton>
      {title ? <span className="text-lg font-bold text-N1">{title}</span> : ''}
    </div>
  );
}
