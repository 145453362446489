import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './contexts/AuthContext';
import { MusicContextProvider } from './contexts/MusicContext';
import { BrowserRouter } from 'react-router-dom';
import { ModalContextProvider } from './contexts/ModalContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-app-polyfill/stable';

import './locales/i18n';
import { ThirdPartyContextProvider } from './contexts/ThirdPartyContext';
import { PromotionContextProvider } from './contexts/PromotionContext';
import { HelmetProvider } from 'react-helmet-async'; // 추가
// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <HelmetProvider>
            <ModalContextProvider>
              <AuthContextProvider>
                <MusicContextProvider>
                  <PromotionContextProvider>
                    <ThirdPartyContextProvider>
                      <App />
                    </ThirdPartyContextProvider>
                  </PromotionContextProvider>
                </MusicContextProvider>
              </AuthContextProvider>
            </ModalContextProvider>
          </HelmetProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
