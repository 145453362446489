import { useEffect, useState } from 'react';
import { getItem, LocalLanguage } from '../../utils/localStorage';

export default function TermsOfUsePage() {
  const [langauge, setLanguage] = useState('');

  useEffect(() => {
    const lan = getItem(LocalLanguage);
    setLanguage(lan);
  });
  if (langauge === 'en') {
    return (
      <main className="w-full h-full mx-auto overflow-y-auto no-scrolbar bg-N0">
        <div className="my-5 mx-[1rem] leading-7">
          <p>
            <strong>Chapter 1: General Provisions</strong>
          </p>
          <p>
            <strong>Article 1 [Purpose]</strong>
          </p>
          <p>
            The purpose of this Agreement is to stipulate the basic matters concerning the rights, obligations, and
            responsibilities of members and the Company, as well as the terms and conditions of service use, in relation
            to the use of the content services (including web, mobile web/app services, subscription services, etc.) and
            related services provided by Digital Nutrition Co., Ltd. (hereinafter referred to as "the Company") under
            the brand name "Soundpill."
          </p>
          <ul>
            <li>
              <strong> Article 2 [Definitions of Terms]</strong>
            </li>
            <li>
              The definitions of terms used in this Agreement are as follows. For terms not defined in this Agreement,
              their definitions shall be governed by the relevant laws and the service guide.
            </li>

            <li>
              1. Content: Refers to data or information expressed in symbols, characters, voices, sounds, images, or
              videos used in the information and communication network as stipulated in Article 2, Paragraph 1, Item 1
              of the "Act on Promotion of Information and Communications Network Utilization and Information
              Protection," which has been produced or processed in a physical or electronic form to enhance its
              usability for preservation and use. <br /> 2. Company: Refers to Digital Nutrition Co., Ltd., which
              engages in economic activities related to the content industry and provides content services (including
              web, mobile web/app services, etc.) and related services. <br /> 3. Member: Refers to a person who has
              entered into a usage agreement with the Company, receives the information disclosed by the Company, and
              can use the services provided by the Company. In principle, this includes "Corporate Members." <br /> 4.
              Corporate Member: Refers to individuals who use the service through a separate contract with a corporation
              or organization with which the Company has a contract to provide services to its employees or members.{' '}
              <br /> 5. Account: Refers to information issued by the Company for the purpose of identifying and managing
              the Member's identity, login information, payment information, etc., during the membership registration
              process. It is a combination of letters, numbers, and special characters. <br /> 6. Posts: Refers to
              information or materials such as text, images, audio, video, and links created by members while using the
              service. <br /> 7. My Library: Refers to a virtual space that stores the list of content downloaded by
              members and posts created by members. <br /> 8. Nickname: Refers to a unique name automatically assigned
              during membership registration, which members can change themselves. <br />
              9. Paid Service: Refers to the services provided by the Company that members can use by paying a certain
              amount to the Company or accepting transaction or contract conditions with the Company or a third party.
              However, using services from paid content providers linked to the Company or affiliate services that have
              entered into a contract with the Company is not considered the use of the Company's Paid Service, and this
              Agreement does not apply to such cases. <br /> 10. Subscription Member: Refers to members who are using a
              Paid Service. The Company may differentiate the services provided to Subscription Members and
              non-Subscription Members. <br /> 11. Payment: Refers to the act of paying a certain amount to the Company
              or a third party designated by the Company through various payment methods to use the Paid Service
              provided by the Company. <br /> 12. Subscription: Refers to the Paid Service usage method where the usage
              fee for a specific period (monthly/annually) is automatically charged, and the usage period is
              automatically renewed through a pre-registered payment method. If a member cancels the Subscription, the
              Paid Service can be used for the remaining period of the Paid Service already charged, and automatic
              payment will not be made from the next usage period. <br /> 13. Subscription Cancellation: Refers to the
              cancellation of Payment for a Paid Service and the immediate termination of the use of the Paid Service.
              When a member cancels a Subscription, the cancellation of the Subscription is automatically applied.
              Refunds for Subscription Cancellation are subject to the provisions of this Agreement. <br /> 14. DRM
              (Digital Rights Management): Rights management information refers to any of the following information or
              numbers or symbols representing the information that is attached to the original or reproduction of a
              copyrighted work protected by the Copyright Act or accompanies its performance, execution, or public
              transmission. o a. Information to identify the work, etc. o b. Information to identify the person holding
              the copyright or other rights protected by the Copyright Act. o c. Information on the method and
              conditions of using the work, etc. <br /> 15. Subscription Ticket, Coupon, or Voucher: Refers to a pass
              that allows the use of the Company's Paid Service, which can be issued online or offline. The usage method
              is separately provided on the Subscription Ticket, Coupon, or Voucher usage guide page.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 3 [Compliance with Laws] </strong>
            </li>
            <li>
              The Company complies with the relevant laws, including the "Act on the Consumer Protection in Electronic
              Commerce, etc.," the "Act on Fair Labeling and Advertising," the "Act on the Regulation of Terms and
              Conditions," the "Act on Promotion of Information and Communications Network Utilization and Information
              Protection," the "Copyright Act," the "Personal Information Protection Act," the "Youth Protection Act,"
              the "Content Industry Promotion Act," and the "Content User Protection Guidelines" as prescribed by the
              Minister of Culture, Sports, and Tourism, in providing content to the Member.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 4 [Interpretation of the Agreement]</strong>
            </li>

            <li>
              Matters not stipulated in this Agreement and the interpretation of this Agreement shall be governed by the
              "Act on the Consumer Protection in Electronic Commerce, etc.," the "Act on Fair Labeling and Advertising,"
              the "Act on the Regulation of Terms and Conditions," the "Act on Promotion of Information and
              Communications Network Utilization and Information Protection," the "Copyright Act," the "Personal
              Information Protection Act," the "Youth Protection Act," the "Content Industry Promotion Act," the
              "Content User Protection Guidelines" as prescribed by the Minister of Culture, Sports, and Tourism, other
              relevant laws, or customary practices.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 5 [Effect and Amendment of the Agreement]</strong>
            </li>
            <li>
              1. The Company shall post the contents of this Agreement on the service page in a manner that allows the
              Member to easily access it. <br /> 2. The Company shall post the contents of this Agreement, the company
              name, the representative's name, the business address, phone number, email address, business registration
              number, telecommunication sales report number, and the personal information manager in a manner that
              allows the Member to easily access it on the service screen. The Agreement may be made available for
              viewing through a linked screen. <br /> 3. The Company may amend this Agreement within the scope of not
              violating the relevant laws, including the "Act on the Regulation of Terms and Conditions," the "Act on
              Promotion of Information and Communications Network Utilization and Information Protection" (hereinafter
              referred to as the "Information and Communications Network Act"), the "Content Industry Promotion Act,"
              the "Act on the Consumer Protection in Electronic Commerce, etc.," and the "Content User Protection
              Guidelines" as prescribed by the Minister of Culture, Sports, and Tourism. <br /> 4. If the Company amends
              the Agreement, it shall specify the effective date and reason for the amendment, and provide notice of the
              amendment on the service page in the manner specified in Paragraph 1, together with the current Agreement,
              from seven days prior to the effective date of the amended Agreement and for a significant period after
              the effective date. The amended Agreement shall become effective on the specified effective date. However,
              if the amendment is disadvantageous to the Member, the Company will post the notice on the service page
              and notify the Member via email or mobile phone, or through other reasonable methods to ensure the Member
              can be aware of the amendment from 30 days prior to the effective date until a significant period after
              the effective date. <br /> 5. If the Company has notified or informed the Member in accordance with the
              preceding paragraph that if the Member does not express an intention to refuse by the effective date of
              the amended Agreement, it will be considered as approval, and the Member does not express an intention to
              refuse, the Member shall be deemed to have agreed to the changed Agreement. If the Member does not agree
              to the amended Agreement, the Member may terminate the usage agreement in accordance with the provisions
              of this Agreement. <br /> 6. This Agreement shall apply from the date the Member agrees to this Agreement
              until the Member withdraws from membership. However, some provisions of this Agreement may continue to
              apply even after membership withdrawal. <br /> 7. The Company shall take necessary technical measures to
              ensure that Members can print and check the full text of this Agreement.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 6 [Notification to Members]</strong>
              <li>
                1. The Company shall not engage in acts that violate laws or this Agreement, and shall do its utmost to
                provide continuous and stable services. <br /> 2. The Company shall establish and operate a security
                system appropriate to the current level of internet security technology and the nature of the services
                provided by the Company, to ensure that Members can safely use the services. <br /> 3. If it is
                impossible to provide normal services due to a national emergency, power outage, service facility
                failure, or excessive service use, the Company may restrict or suspend all or part of the content. In
                such cases, the Company shall notify the Member of the reason and period of restriction or suspension,
                either before or after the event. <br /> 4. The Company shall address any opinions or complaints raised
                by Members regarding the use of the service, provided they are deemed legitimate. However, if immediate
                processing is difficult, the Company shall inform the Member of the reason and processing schedule. The
                process shall be communicated to the Member through the bulletin board or other methods. <br /> 5. The
                Company shall compensate the Member for any damages incurred as a result of the Company violating its
                obligations as stipulated in this Agreement.
              </li>
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 8 [Protection and Management of Personal Information]</strong>
            </li>
            <li>
              1. The Company may collect the minimum amount of personal information necessary to provide the service.{' '}
              <br /> 2. When the Company collects personal information that can identify the Member, the Company shall
              obtain the Member's consent. <br /> 3. The Company shall not use the information provided by the Member
              during the service application process or the information collected under Paragraph 1 for purposes other
              than those for which it was collected, nor shall it provide this information to third parties without the
              Member's consent. If the Company violates this provision, it shall be fully responsible. However, the
              following cases are exceptions: <br /> 1. When it is necessary for statistical purposes, academic
              research, or market research, and the information is provided in a form that does not identify specific
              individuals. <br /> 2. When it is necessary for billing related to the provision of content. <br /> 3.
              When it is necessary for identity verification to prevent fraud. <br /> 4. When it is required by
              provisions of this Agreement or by law. 5. When it is necessary for the Company's services and the
              information is provided in a form that does not identify specific individuals. <br /> 4. The Member may
              withdraw consent under Paragraphs 2 and 3 at any time, but if consent to collect or provide information
              necessary for service provision is withdrawn, service provision may be restricted. <br /> 5. The Company
              shall endeavor to protect the personal information of the Member in accordance with the "Act on Promotion
              of Information and Communications Network Utilization and Information Protection" and other relevant laws.
              The protection and use of personal information shall be governed by related laws and the Company's privacy
              policy. <br /> 6. The Company is not responsible for the exposure of the Member's information due to
              reasons attributable to the Member.
            </li>
          </ul>
          <ul>
            <li>
              <strong> Article 9 [Membership Registration]</strong>
            </li>
            <li>
              1. A Member may register for membership using a mobile phone number through the Digital Nutrition web/app
              site to use the Company's services. However, if it is difficult to use a mobile phone number, the Company
              may allow separate membership registration methods according to its exception policies. <br /> 2. If a
              Member is under 14 years of age, they may register for membership only after obtaining consent from a
              legal guardian, such as a parent. <br /> 3. During the membership registration process, a "Nickname" is
              assigned, which the Member can modify during or after registration. <br /> 4. The Company shall consider
              the Member to have agreed to this Agreement when the Member clicks the "Agree" button on the membership
              registration page or begins using the service. The membership agreement is established when the Company
              approves the membership application. <br /> 5. Before completing the membership registration, the Member
              must be fully aware of the circumstances announced by the Company regarding the relevant Paid Service and
              supported devices, ensuring that they can engage in transactions accurately and without error. <br /> 6.
              The Company shall approve the membership application without delay after confirming the application
              details. However, the Company may withhold or reject approval in the following cases and may terminate the
              usage agreement afterward: <br /> 1. If the Member has previously lost membership status under this
              Agreement. <br /> 2. If the Member uses another person's name. <br /> 3. If a Member under 14 years of age
              fails to complete the necessary procedures, such as obtaining legal guardian consent, or provides false
              information. <br /> 4. If the Member provides false information or does not provide the information
              requested by the Company. <br /> 5. If approval is not possible due to reasons attributable to the Member.{' '}
              <br /> 6. If the application violates relevant laws, other regulations, or any other provisions. <br /> 7.
              If service provision is not possible due to lack of technology or facilities. <br /> 7. The service usage
              agreement is established at the time the Company's approval reaches the Member. <br /> 8. After completing
              the registration, a personal "Library" is created for the Member. The Member can set the Library to be
              public or private. If the Library is set to public, other Members can view the book list in the Library
              and the posts created by the Member. <br /> 9. The Company may request real-name verification and identity
              authentication through a specialized institution depending on the type of Member. <br /> 10. The Company
              may impose usage restrictions or grade-specific limitations to comply with the "Act on the Promotion of
              the Motion Pictures and Video Products" and the "Youth Protection Act" in relation to the Member. <br />{' '}
              11. The Member may view and modify their personal information at any time through the personal information
              management screen. <br /> 12. If the information provided during membership registration changes, the
              Member must update it through the web/app or notify the Company of the changes via customer service. The
              Company is not responsible for any disadvantages caused by the Member's failure to notify the Company of
              changes.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 10 [Obligations of Members]</strong>
            </li>
            <li>
              1. If the Member's information changes, they must update it through the information change menu. The
              Member is responsible for any damage caused by not updating the information. <br /> 2. The login
              information is for the Member's personal use only and must not be shared with others. If the Member
              becomes aware that a third party is using their information, they must immediately notify the Company and
              follow the Company's instructions. <br /> 3. The Member must not engage in any activities that infringe
              copyright, such as copying, selling, editing, displaying, distributing, or broadcasting any content
              received from the Company without the Company's prior consent. <br /> 4. The Member must comply with the
              relevant laws, the provisions of this Agreement, usage guidelines, service usage information, and any
              notices provided by the Company, and must not engage in any activities that interfere with the Company's
              business or fall under the following categories: <br /> 1. Registering false information during membership
              application or member information changes. <br /> 2. Attempting abnormal registration or interfering with
              service provision during events. <br /> 3. Collecting, storing, disclosing, or using others' information
              fraudulently. <br /> 4. Providing the Member's information to others without the Company's consent,
              allowing others to use the Company's services, or transferring or gifting the service usage rights or any
              other contractual position to others. <br /> 5. Using illegal programs to bypass technical protection
              measures or accessing services through abnormal routes in violation of the Copyright Act. <br /> 6.
              Copying, modifying, distributing, selling, transferring, or renting parts of the service, content, or
              software without the Company's consent, allowing others to use it, or attempting to reverse-engineer,
              extract source code, or otherwise replicate, disassemble, or alter the service. <br /> 7. Impersonating
              the Company's operators, employees, or the Company itself, or misusing related information. <br /> 8.
              Changing or transmitting information posted by the Company on the service. <br /> 9. Infringing the
              copyrights, trade secrets, patent rights, or other intellectual property rights of the Company or third
              parties. <br /> 10. Using the provided service in public places or for commercial purposes instead of
              private purposes. <br /> 11. Distributing false information related to the Company or its services for
              personal gain or using the service abnormally. <br /> 12. Harassing, threatening, or defaming the Company,
              other Members, or third parties. <br /> 13. Publicly disclosing or posting obscene, violent messages, or
              other information contrary to public morals. <br /> 14. Interfering with the Company's work through
              abusive language, threats, sexual harassment, or repeated complaints when contacting customer service.{' '}
              <br /> 15. Engaging in illegal activities that violate current laws. <br /> 16. Engaging in any other
              activities that interfere with the Company's sound service operation. <br /> 5. If the Member engages in
              any of the prohibited activities mentioned above, the Company may take appropriate actions based on the
              severity of the violation, including service suspension, contract termination, reporting to investigative
              authorities, etc. <br /> 6. The Member may not engage in business or advertising activities contrary to
              the Company's specified usage purposes and methods without the Company's prior permission, and the
              Member's service usage must not infringe on the Company's property rights, business rights, or business
              model. <br /> 7. To access harmful media materials for youth, the Member must be at least 19 years old
              (including those who reach 19 years of age on January 1 of the year they turn 19), and they must receive
              adult authentication through verification methods stipulated by the "Act on Promotion of Information and
              Communications Network Utilization and Information Protection" and the "Youth Protection Act." If adult
              authentication is not completed, access to the relevant service will be restricted. <br /> 8. The Company
              is not responsible for any problems arising from the Member's violation of obligations stipulated in this
              Article, unless the Company is responsible for the Member's violation.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 11 [Provision of Information and Advertisement Placement]</strong>
            </li>
            <li>
              1. The Company may place advertisements using the information entered by the Member in connection with the
              operation of the service. The Member agrees to the placement of personalized advertisements displayed
              during the use of the service. <br /> 2. The Company may provide various information deemed necessary
              during the use of the service to the Member through methods such as the homepage, content screens, or
              announcements, and may also provide such information via email or other methods if the Member has given
              prior consent. However, the Member may refuse to receive such communications from the Company at any time
              through email or other means. <br /> 3. The Company is not responsible for any loss or damage arising from
              the Member's participation in promotional activities of advertisers or communication or transactions with
              advertisers through advertisements displayed on the service or through the service. However, this does not
              apply if the Company knew or should have known of illegal activities, such as the posting of false
              information by the advertiser, and failed to act due to gross negligence. <br /> 4. The Member may refuse
              to receive advertisements at any time through the management menu, except for transaction-related
              information and responses to customer inquiries as required by relevant laws.
            </li>
          </ul>
          <ul>
            <li>
              <strong> Article 12 [Membership Withdrawal and Suspension of Membership Qualifications]</strong>
            </li>
            <li>
              1. The Member may request to withdraw from membership at any time, and the Company shall promptly process
              the membership withdrawal request. <br /> 2. Upon membership withdrawal, the Member will no longer be able
              to access previously purchased or downloaded content, posts created, or various forms of points. If the
              Company has provided instructions on preservation methods or deletion and the Member fails to take action,
              the Company is not responsible for the loss. <br /> 3. The Company may withdraw or suspend a Member's
              membership in the following cases: <br /> 1. If the Member registered false information during the
              membership application, content usage application, or changes. <br /> 2. If the Member interferes with
              another person's use of the service or engages in actions that threaten the order of electronic commerce,
              such as stealing information. <br /> 3. If it is confirmed that the Member joined through fraudulent
              means, such as using another person's information. <br /> 4. If the Member uses, transmits, or posts
              prohibited information (e.g., computer programs) as determined by the Company. <br /> 5. If the Member
              infringes on the intellectual property rights, such as copyrights, of the Company or third parties. <br />{' '}
              6. If the Member damages the reputation of the Company or third parties or interferes with their business.{' '}
              <br /> 7. If the Member engages in activities prohibited by law or this Agreement, or that violate public
              morals, during the use of the Company's services. <br /> 8. If the Member circumvents or disables the
              Company's technical protection measures. <br /> 9. If the Member uses the services provided by the Company
              for purposes other than their normal intended use or through improper methods. <br /> 10. If the Member
              abnormally abuses the service with the intention of interfering with the Company's business. <br /> 11. If
              the Member violates any of the items listed in Article 10, Paragraph 4 of this Agreement. <br /> 4. If the
              Company restricts or suspends a Member's qualifications, and the same violation is repeated more than
              twice or the cause is not corrected within 30 days, the Company may terminate the Member's qualifications.{' '}
              <br />
              5. If the Company terminates a Member's qualifications, the Company shall cancel the Member's
              registration. In such cases, the Company shall notify the Member and give the Member at least 30 days to
              provide an explanation before canceling the registration. If the Member's explanation is deemed justified,
              the Company shall immediately restore the Member's qualifications. However, if the Member does not provide
              an explanation within the specified period, it will be considered as consent to the cancellation of the
              registration.
            </li>
          </ul>
          <ul>
            <li>
              <strong> Chapter 3:</strong>
            </li>
            <li>
              <strong>Service Article 13 [Provision and Use of Services]</strong>
            </li>
            <li>
              1. The Company shall commence the service upon approval of the Member's application for membership.
              However, for some services, the service may commence on a specified date, and for Paid Services, the
              service is provided after payment is completed. <br /> 2. The Company may provide a free trial period for
              the Member for the purpose of service experience. <br /> 3. The service usage time shall, in principle, be
              24 hours a day (00:00-24:00) throughout the year, except when it is impossible due to the Company's
              business or technical reasons. <br /> 4. A Member can use the service on up to 5 devices (PC, tablet,
              smartphone, etc.) with one account, but simultaneous access to the service is not allowed. This provision
              may be changed at the discretion of the Company. <br /> 5. The services provided by the Company and all
              content used through these services must be used only for personal and non-commercial purposes. Even for
              non-commercial purposes, the content must not be shared with others without the explicit consent of the
              Company. Additionally, the Member must not use the service for public performances or commercial purposes.{' '}
              <br /> 6. To ensure stable service provision and copyright protection, the Company may limit the number of
              book downloads by the Member without prior notice if abnormal book download frequencies are detected, or
              if illegal programs that bypass technical protection measures are detected. <br /> 7. The Company may
              modify, change, or discontinue some or all of the services according to the Company's business plans and
              service operation policies. In such cases, unless otherwise stipulated by relevant laws, the Company shall
              not provide separate compensation to the Member. However, if the modification is deemed disadvantageous to
              the Member, the Company will notify the Member via phone, email, in-app notifications, or other reasonable
              means. <br /> 8. When a Member accesses the Company's site via a wired or wireless network, or when using
              the Company's services through an application installed on a device connected to a wired or wireless
              network, or when using the Company's services overseas, separate data communication charges may be
              incurred according to the communication plan agreed upon with the Member's telecommunications carrier.
              These data communication charges are separate from the Paid Service fees, and the Company is not
              responsible for such charges. <br /> 9. In addition to this Agreement, the Company may have separate
              management policies for "Posts" and other content related to the use of services. This includes
              restrictions on the maximum storage duration and capacity of posts or other content, the maximum number of
              connected devices, etc. The Company is not responsible for the deletion or failure to store posts or other
              content on the service due to reasons not attributable to the Company. <br /> 10. The Company may limit
              the service provision area to South Korea in accordance with this Agreement. <br /> 11. For Corporate
              Members, the Company may provide reading information of books to the relevant corporate or organizational
              representatives at their request, to confirm educational hours.
            </li>
          </ul>
          <ul>
            <strong>Article 14 [Renewal of Contract and Automatic Payment]</strong>
            <li>
              1. If the free usage period of the content expires and it transitions to a paid service, the Company shall
              clearly notify the Member in advance on the payment page and obtain the Member's consent. <br /> 2. If the
              Company intends to renew the paid service contract, it shall clearly notify the Member in advance on the
              payment page and obtain the Member's consent. <br /> 3. If the Company fails to obtain the Member's
              consent in accordance with Paragraphs 1 or 2, the content usage contract shall expire at the end of the
              free usage period or the contract period.
            </li>
          </ul>
          <ul>
            <li>
              <strong> Article 15 [Changes, Suspension of Service, and Damage Remedies]</strong>
            </li>
            <li>
              1. In cases where there is a significant defect that makes normal use of the content difficult, the
              Member's damage remedies shall be as follows: <br /> 1. The Company shall supply complete content again.
              <br /> 2. If the Company fails to supply complete content within 24 hours, the Company shall compensate
              the Member for damages up to the amount paid by the Member. <br /> 2. In cases where normal use of the
              content is possible but there are minor defects, the Member's damage remedies shall be as follows: <br />
              1. The Company shall supply complete content again. <br />
              2. If the Company fails to supply complete content within 24 hours, the Company shall compensate the
              Member for damages corresponding to the portion of the payment amount attributable to the defect.
            </li>
          </ul>
          <ul>
            <li>
              <strong>
                Article 16 [Notification of Content Use Environment Restrictions, Download, and Playback Restrictions]s
              </strong>
            </li>
            <li>
              1. The Member must download the content only through the Soundpill app provided by the Company, and the
              downloaded content must be played only through the app. If the content is accessed through an abnormal
              route, the Company may restrict the Member's use of the service and claim damages for any resulting
              losses. <br /> 2. During the paid service usage period, the Member may re-download or extend the playback
              period for the content files permitted for use. <br /> 3. The playback use of the content files downloaded
              by the Member for the paid service is only available during the paid service usage period. If the paid
              service usage period expires, payment is not made, or the subscription is canceled for any reason, the
              downloaded content files cannot be played.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 17 [Attribution of Rights and Use of Works]</strong>
            </li>
            <li>
              1. The Member retains the copyright to the text, images, audio, videos, links, and other information
              (hereinafter referred to as "Posts") created by the Member while using the service, and the Member is also
              solely responsible for any infringement of intellectual property rights of others caused by the Posts.
              However, if the Company knew or could have easily known that the Member's Posts infringed on the
              intellectual property rights of others and failed to take reasonable precautions, the Company may share
              liability with the Member for damages to others caused by the Posts. <br /> 2. The Company may use the
              Posts created by the Member in the following ways and conditions as stipulated by relevant laws. However,
              if the Company wishes to use the Posts in ways and conditions not stipulated in this paragraph, the
              Company must obtain the Member's permission. <br /> 1. Utilizing the Member's Posts within the necessary
              scope for service promotion (exposure within the Company's services, using the Posts for service
              promotion, creating promotional materials for service operations and content, editing or modifying the
              Posts for use, utilizing the Posts for the cover of electronic or paper books, etc.). <br /> 2. Using the
              Posts for research purposes, such as statistical analysis to improve and enhance the service (using the
              Posts as statistical data for technology in the field of artificial intelligence, content planning,
              improving the user environment, etc.). <br /> 3. If the Posts registered by the Member violate the
              "Copyright Act" or other related laws, or if the Posts are recognized as infringing on the rights of
              others, such as invasion of privacy or defamation, the Company may temporarily block access to or delete
              the relevant Posts. <br /> 4. All copyright to the content created and provided by the Company belongs to
              the Company. <br /> 5. Unless the Member and the Company enter into a separate written agreement granting
              explicit rights to use the service's brand features, the Member is not granted the right to use the
              Company's trademarks, service marks, logos, domain names, and other identifiable brand features. <br /> 6.
              Posting Posts on the service by the Member is considered permission for other Members to use the Posts
              within the service or for the Company to use the Posts in search results. However, the Member can set
              options for the Posts' exposure through the service's management functions. <br /> 7. The Company may
              change or relocate the posting location of the Posts due to service policy or integration with other
              services operated by the Company. In such cases, prior notice will be given.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 18 [Program Installation and Data Collection]</strong>{' '}
            </li>
            <li>
              1. The Company may guide the Member to install and update applications or separate programs necessary to
              provide the service. However, the Member may refuse the installation and updates if they do not wish to do
              so or if their device does not support the service, but this may limit service use. <br /> 2. The programs
              provided by the Company are intended solely for the use and enjoyment of the service, and no part of these
              programs may be copied, modified, distributed, sold, or rented. However, if the Member has a legal basis
              or has obtained approval from the Company, such actions may be allowed. <br /> 3. The Company may collect
              and use data from the service usage device with the prior consent of the Member. However, the Company may
              only use the collected information for the purpose of improving the service and providing appropriate
              services, and it may not be used for any other purposes. <br /> 4. Upon prior consent, the Company may
              access the following functions on the Member's smartphone when installing the app: <br /> 1. Phone:
              Permission required for voice control when using other media apps while using the audiobook or TTS mode.{' '}
              <br /> 2. Storage: Permission required to store content and ensure continuous access afterward.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 19 [Management of Posts]</strong>
            </li>
            <li>
              1. The responsibility and rights for the Posts created by the Member within the service lie with the
              Member who registered the Posts. <br /> 2. The Company does not monitor or manage the content created by
              the Member and is not responsible for it. However, if the Company becomes aware of illegal content within
              the Member's Posts due to complaints from other Members and fails to take reasonable action within a
              reasonable period, this exemption does not apply. <br /> 3. The Company does not guarantee the
              reliability, truthfulness, or accuracy of the Posts created by the Member. However, if the Company becomes
              aware that the content of the Member's Posts is false or contains information that could harm third
              parties and fails to take reasonable action within a reasonable period, this exemption does not apply.{' '}
              <br /> 4. If the content of the Member's Posts violates the "Act on Promotion of Information and
              Communications Network Utilization and Information Protection" and the "Copyright Act" or other related
              laws, the rights holder may request the suspension or deletion of the relevant Posts in accordance with
              the procedures stipulated by the relevant laws, and the Company may take action according to the relevant
              laws. <br /> 5. The Company may restrict the posting of Posts containing the following content on the
              service: <br /> 1. Content that invades privacy, defames others, or uses abusive language, causing
              discomfort to others. <br />
              2. Content that undermines public morals or insults specific groups, organizations, or religions, causing
              discomfort to others. <br /> 3. Content that infringes on the rights of others based on unverified or
              baseless information. <br /> 4. Content that infringes on others' copyrights. <br /> 5. Content that
              includes personal information of others. <br /> 6. Content that includes advertisements/promotions. <br />{' '}
              7. Content that repeatedly posts the same message. <br /> 8. Content that contains malware or otherwise
              disrupts the Company's service. <br /> 9. Content that the Company deems to have a negative impact on
              other Members' ability to use the service. <br /> 6. Posts that the Member has set to private cannot be
              viewed by others, including the Company. However, if the Company is requested to provide information by
              the court, investigative agencies, or other administrative agencies, or if required by law, the Company
              may allow relevant parties to view the Posts. <br /> 7. If the Member terminates the membership contract,
              all works created by the Member may be deleted. However, if the work was created through joint authorship,
              the Posts may remain in the Posts of the other joint author, and the Company is not responsible if the
              work is not deleted and is reposted due to preservation by a third party or unauthorized reproduction.{' '}
              <br /> 8. To protect other Members, and to serve as evidence in response to requests from courts,
              investigative agencies, or other relevant agencies, the Company may retain the Member's ID and information
              in accordance with the Company's Privacy Policy and relevant laws, even after the termination of the
              membership contract.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Chapter 4:</strong>
            </li>
            <li>
              <strong>Use of Paid Services and Payment Article 20 [Paid Services and Paid Service Members]</strong>
            </li>
            <li>
              1. The Paid Service shall commence from the time the Company approves the Member’s application for the
              Paid Service (in some cases, from the designated date specified in advance). However, if the Company is
              unable to commence the Paid Service due to technical reasons or other circumstances, the Company shall
              notify the Member in advance according to Article 3 (Effect and Amendment of the Terms and Conditions).{' '}
              <br /> 2. When a Member applies for the Paid Service, the Company shall notify the Member of the details
              of the application. <br /> 3. If the Member finds any discrepancy or error in the notification received,
              they must request a correction or modification from the Company, and the Company shall handle it
              accordingly. However, if the Member has already made a payment, the provisions of these Terms regarding
              the cancellation of paid services and refunds shall apply. <br /> 4. The Company offers the following
              types of Paid Services, and may add or change the content of the service depending on the Company’s
              circumstances and other conditions: <br /> 1. Subscription-based Paid Service: A service where the Member
              registers a payment method in advance, and the service fee is automatically charged on a monthly/yearly
              basis, and the service period is automatically renewed. <br /> 2. Expiration-based Paid Service: A service
              that ends when the available usage period expires. <br /> 3. Direct Sales Paid Service: A service where
              the Member directly purchases and uses the Paid Service provided by the Company. <br /> 4. Agency Sales
              Paid Service: A service where the Company sells the Paid Service through a third party, and the usage
              method of the product follows the instructions provided by the affiliated company. <br /> 5. Corporate
              Member Paid Service: A service provided where a corporate entity or organization that has entered into a
              contract with the Company pays the usage fee for the Paid Service. In this case, the obligations related
              to the payment of the usage fees for the Paid Service, as specified in these Terms, shall be borne by the
              corporate entity or organization to which the Corporate Member belongs. <br /> 5. If a Member has
              subscribed to a Paid Service in partnership with a mobile phone manufacturer or telecommunications
              company, and if conditions such as suspension/termination of the mobile plan, change to another plan,
              change in the membership type, or change in the device no longer match the agreed conditions at the time
              of subscription, the Member may not be able to receive the benefits such as discounts provided by the
              partnered service. Therefore, the Member should check the service information/usage guide provided by the
              Company in advance before such changes. <br /> 6. If the contract for the content registered by the Member
              in the "My Library" section between the author and the Company expires or changes, the Member may no
              longer be able to download the content. However, if such changes occur, the Company must promptly provide
              information about the changes to the Member. <br /> 7. The terms of the contract between the Company and
              individuals, groups, or corporations holding copyright (hereinafter referred to as the "Rights Holders")
              may restrict or change the usage area, content file attributes, or service availability of individual Paid
              Services or content files either before or after the contract. The Company shall notify the Member if such
              changes occur. <br /> 8. Upon the request of the Rights Holders, the Company may change the types of
              devices supporting the Paid Service, the number of devices that each Member can use, the number of
              simultaneous connections, etc. <br /> 9. If the Member terminates the Paid Service, the content
              information registered in the Member’s "My Library" will be retained for 12 months from the time of
              termination and then permanently deleted, and cannot be recovered.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Article 21 [Paid Service Usage Contract]</strong>
            </li>
            <li>
              1. The Paid Service Usage Contract is established when the Member agrees to these Terms, applies for the
              Paid Service, and the Company accepts it (the moment the completion of the purchase/payment for the Paid
              Service is indicated to the Member). <br /> 2. Before the Member makes a payment for the Paid Service, the
              Company shall provide the following information on the purchase page so that the Member can understand and
              complete the transaction without errors: <br /> 1. The content, price, usage period, usage method,
              conditions for cancellation and termination of subscription, refund policies, and other related matters of
              the Paid Service. <br /> 2. Confirmation of measures taken by the Company regarding content that is not
              eligible for withdrawal (refund). <br /> 3. If any of the reasons listed in Article 12 [Membership
              Withdrawal and Suspension of Membership Qualifications] occur, the Company may refuse or withhold
              acceptance of the application for the Paid Service. <br /> 4. When filling out the application for the
              Paid Service, the Member must provide accurate information consistent with their current facts, and must
              accurately provide the payment method and payment information required for the Paid Service to the
              Company. <br /> 5. If there are changes to the information provided in the previous paragraph, the Member
              must promptly notify the Company following the procedures established by the Company, and the Company must
              reflect the changes without delay. <br /> 6. The Member can use the Paid Service with the payment methods
              designated by the Company, and may use subscription passes, coupons, or gift certificates issued by the
              Company or third parties through various promotions or events. <br /> 7. The Company may set transaction
              limits such as cumulative monthly payment amounts, payment limits, etc., per Member based on the Company’s
              internal policy, external payment companies (PG companies, banks, credit card companies, etc.), or other
              related laws. If the Member wishes to use Paid Services exceeding the transaction limit set by the
              Company, the Member may be unable to use additional Paid Services due to exceeding the transaction limit.{' '}
              <br /> 8. The timing of availability of products provided by the Company may vary depending on the
              approval status of each copyright holder. <br /> 9. Event-based mileage provided by the Company is not
              convertible to cash. If the Member terminates the membership contract or has their membership restricted,
              or if 30 days have passed since the provision of such mileage, it will automatically expire.
            </li>
          </ul>
          <ul>
            <li>
              <strong> Article 22 [Special Provisions for Contracts with Minors]</strong>
            </li>
            <li>
              The payment for services by a minor Member under the age of 19 must, in principle, be made under the name
              of the guardian or with the guardian’s consent. The guardian may cancel the contract entered into by the
              child (minor) without their consent. However, if the minor Member uses the resident registration number of
              another adult or uses payment information without consent in a deceptive manner that leads the Company to
              believe that the minor is an adult, the contract cannot be canceled even without the consent of the legal
              guardian.
            </li>
          </ul>
          <ul>
            <li>
              <strong> Article 23 [Sale of Real Coupons and Goods]</strong>
            </li>
            <li>
              1. The Company may sell goods such as books to Members. <br /> 2. The Member shall apply for the purchase
              in the following ways, and the Company shall provide the following information in a manner that is easily
              understandable to the Member. However, the Company may choose not to apply Clauses 2 through 4. <br /> 1.
              Search and select goods. <br /> 2. Enter the recipient's name, address, phone number (or mobile phone
              number), etc. <br /> 3. Confirm information related to the terms of service, service restrictions,
              delivery fees, installation fees, and other cost burdens. <br /> 4. Indicate consent or refusal to the
              terms of service by checking "Agree" (e.g., clicking a mouse button). <br /> 5. Apply for the purchase of
              goods and confirm or agree to the Company’s confirmation. <br /> 6. Select the payment method. <br />
              7. Other similar methods to Clauses 1 to 6. 3. The Company shall specify the delivery method, the party
              responsible for delivery costs by method, and the delivery period by method for the goods purchased by the
              Member. However, in cases of force majeure such as public holidays, other holidays, or natural disasters,
              the period required for delivery may be excluded, and if the delivery period is exceeded due to other
              reasons, the Company must notify the Member immediately. <br /> 4. If the Company is unable to deliver the
              goods applied for by the Member due to reasons such as a stockout, the Company shall promptly notify the
              Member, and if payment has already been received, refund the full or partial payment within three business
              days from the date the Member requests a refund. <br /> 5. The Member may request a return or exchange
              within seven days from the day they receive the goods under the Consumer Protection in Electronic Commerce
              Act. However, the Member may not request a return or exchange in the following cases: <br /> 1. The goods
              are lost or damaged due to reasons attributable to the Member. <br /> 2. The value of the goods is
              significantly reduced due to the Member's use or partial consumption. <br /> 3. The goods' packaging,
              which can be copied, has been opened or damaged. <br /> 4. Other reasonable cases where a refund or
              exchange request is not feasible due to the nature of the goods. <br /> 6. The Member may request a
              refund, return, or exchange within 30 days from the day they receive the goods in the following cases, and
              all return costs will be borne by the Company or the seller: <br /> 1. The content of the goods differs
              from the displayed or advertised content, or the contract content is not fulfilled. However, in this case,
              the Member must request a refund within 30 days from the day they knew or could have known the fact, and
              at the latest within three months from the receipt of the goods. <br /> 2. The goods are damaged, broken,
              or contaminated without the Member's fault. <br />
              3. The goods are delivered later than the delivery period indicated in the advertisement. <br /> 7. If the
              Member returns the goods for reasons other than the above cases, they must bear all return costs. <br />{' '}
              8. In the following cases, cancellation, return, or exchange due to the Member's simple change of mind or
              mistake is not allowed: <br /> 1. Goods that have been opened by the Member. <br /> 2. Goods where it was
              indicated that return or cancellation due to a change of mind is not possible at the time of purchase.{' '}
              <br />
              3. Goods that cannot be resold after return because their condition at the time of purchase and receipt
              has not been preserved (e.g., removal of wrapping, disposal of cases, disposal of supplements). <br />
              9. The Company will proceed with the refund process after receiving the returned goods from the Member. If
              the goods are lost during the return process through another delivery company or post office, the Company
              will not refund the goods.
            </li>
          </ul>
          <ul>
            <li>
              <strong> Article 24 [Changes to Paid Services]</strong>
            </li>
            <li>
              1. The Company may change the price of the Paid Services supplied due to operational or technical needs,
              and shall notify the Members in advance according to Article 5 (Effect and Amendment of the Terms and
              Conditions). <br /> 2. Members who have already purchased/subscribed to Paid Services may apply for
              changes according to the Company's policy and may choose to change immediately or schedule a change for
              the following month. <br /> 3. If the Member selects an immediate change, the requested service will be
              replaced immediately. However, the Company will proportionately adjust the remaining period of the
              purchased/subscribed service and the price of the requested service, and apply a new billing cycle. <br />{' '}
              4. If the Member selects a scheduled change, they may continue to use the existing purchased/subscribed
              service until the end of the month they requested the change, and the requested service will be applied
              from the next day after the end of the usage period. <br /> 5. Paid Services paid for through Apple or
              Google In-App Purchase (IAP) may be sold at different prices considering the fees according to Apple and
              Google's policies. <br />
              6. If the Company provides the Paid Service through a partnership, the policies of the respective partner
              company apply. Article 25 [Termination and Cancellation of Paid Service Use] <br />
              1. For "subscription-based" Paid Services, Members can cancel their subscription through the management
              menu or customer service. If a Member cancels the subscription, automatic payment for the Paid Service
              will not be made from the next billing period onward. The Paid Service can still be used normally for the
              remaining period for which payment has already been made. <br /> 2. A Member can request to cancel a
              subscription to Paid Services unless they have already downloaded digital content (including starting the
              download) or accessed it through real-time streaming services. If the subscription is canceled within
              seven days of payment, the Member can receive a 100% refund. If canceled after seven days, 90% of the paid
              amount will be refunded. This provision also applies to subscription-based Paid Services. <br /> 3. If the
              goods or services provided differ from the advertised content or contract, the Member may request a refund
              within 30 days of realizing the discrepancy. <br /> 4. In the cases outlined in Clauses 1 and 2, the
              Member may apply for subscription cancellation or termination at any time through the app's management
              page, customer service phone/1:1 inquiry, etc., and the Company will process these requests promptly
              following relevant laws and procedures. <br /> 5. If a Member requests a cancellation or refund after
              using the purchased goods or services, a refund will not be possible. <br /> 6. Upon confirming the
              Member's subscription cancellation, the Company will request a stop or cancellation of the payment from
              the respective payment provider within three business days, and a refund will be processed using the same
              payment method. However, depending on the specific payment method and circumstances, there may be
              differences in the refund method and the period in which the refund is processed. 1. For payment methods
              that require payment confirmation (e.g., credit cards), the refund will be processed within three business
              days of confirmation. <br /> 2. If a predetermined period for canceling or stopping payment has passed as
              per an agreement between the payment provider and the Company, the refund will be handled according to the
              agreed terms. <br /> 3. If the Member has gained benefits from using the Paid Service/subscription or
              requests a mid-term cancellation, the refund amount may be adjusted accordingly. <br /> 4. If the Member
              does not promptly provide the necessary information or documents for the refund process (e.g., bank
              account details, ID copy), the refund process may be delayed. <br /> 5. If the Member has expressly
              indicated a specific preference for how the refund should be handled. <br />
              7. The Company is not obligated to refund the payment for Paid Services that were gifted to the Member or
              obtained for free through promotions, where the Member did not directly pay for the service. <br /> 8. If
              the Company terminates the contract or restricts service use under Article 12 [Membership Termination and
              Suspension], any refund owed to the Member will be processed according to the refund criteria specified in
              this Article. The Member may object to such actions through the procedures set by the Company, and if
              deemed justified, the Company will immediately resume service. <br /> 9. The Company will not claim
              penalties or damages from the Member for canceling the subscription and the termination or cancellation of
              the contract does not affect the Member's liability for damages. However, the Company may claim a penalty
              for the early termination of services with a committed usage period. <br />
              10. Members who wish to maintain their Paid Service subscription must ensure that no overdue payments or
              delays in payment methods occur. 11. If a Member violates the above provision and defaults on payment
              during the term of the subscription service, the subscription may be automatically terminated on the day
              of the payment delay. <br />
              12. If a Member terminates their membership contract while using Paid Services, the service will be
              terminated immediately. The Company will refund the remaining balance after deducting the service usage
              period, usage records, shipping costs, any benefits obtained from the service or product, and any
              discounts applied. The Member's information and usage records will be handled per the relevant laws unless
              otherwise stated in these terms. ---
            </li>
          </ul>
          <ul>
            <li> Article 27 [Payment of Fees and Refunds for Overpayments]</li>
            <li>
              1. Members must pay the fees for the content as presented by the Company. <br />
              2. If the Member makes an overpayment, the Company must refund the full amount using the same payment
              method. If it is not possible to refund using the same method, the Company must notify the Member
              immediately and refund using the method chosen by the Member. <br /> 3. If the overpayment is due to the
              Member's fault, the Company may deduct reasonable expenses for processing the refund. <br />
              4. If the Company rejects the Member's refund request for the overpayment, it must prove that no
              overpayment occurred. <br /> 5. The detailed procedures and other matters regarding refunds for
              overpayments are as follows: <br />
              1. When the Company or Member becomes aware of the overpayment, they must notify the other party through
              the methods provided by the Company, such as email or the service homepage. <br />
              2. The Company may request the necessary information for the refund from the Member (e.g., name, payment
              proof, phone number, refund account). <br /> 3. The Member must provide the requested information to the
              Company. <br />
              4. The Company must process the refund within seven days from the date the Member provides the information
              (or offset the amount against future charges if the Member has expressly indicated such a preference).
            </li>
          </ul>
          <ul>
            <li> Article 28 [Damages]</li>
            <li>
              1. The Company is liable for damages only for actual harm caused by defects in the service that result in
              the loss, damage, or deletion of the content provided by the Company's paid services. However, the Company
              is not responsible for any damage caused to the Member that was not due to the Company’s or its employees'
              intentional or negligent actions. <br /> 2. If a Member suffers damage due to reasons not specified in
              these terms that are attributable to the Company, the Company will be liable for compensation according to
              the "Guidelines for the Protection of Content Users" and related laws and regulations. <br /> 3. If the
              Company suffers damages due to a Member’s violation of these terms, the Member is responsible for
              compensating the Company for those damages. <br />
              4. If the Member's fault results in the restriction or termination of service usage based on these terms,
              the Member will lose all rights to the digital content they possess, and the Company is not obligated to
              refund any payments for such digital content.
            </li>
          </ul>
          <ul>
            <li> Article 29 [Limitation of Liability]</li>
            <li>
              1. The Company is not responsible for service disruptions caused by the Member's failure to comply with
              the terms, service usage methods, or standards. However, this does not apply if the Company is also
              responsible for the service disruption. <br /> 2. The Company is not liable for any transactions or
              interactions between Members or between a Member and a third party conducted through the service. However,
              if the Company was aware of illegal activities in such transactions and failed to notify the Member within
              a reasonable time, this exemption does not apply. <br /> 3. The Company may modify, discontinue, or change
              all or part of the free services due to operational or technical reasons, and unless specified by law, the
              Company is not obligated to provide separate compensation. <br /> 4. The Company is not liable for damages
              incurred by the Member or third parties for the following reasons: <br /> 1. If paid services cannot be
              provided due to force majeure such as natural disasters, war, or technical defects that are difficult to
              resolve. <br />
              2. If the Member neglects to manage their ID or password. <br />
              3. If service usage is not possible due to public telecommunication line failures outside the Company’s
              control. <br /> 4. If service usage is disrupted due to reasons not attributable to the Company. <br /> 5.
              The Company does not guarantee the reliability, accuracy, or content of the information, materials, or
              facts posted or transmitted within the service by Members or third parties, and is exempt from liability
              unless there is intentional or gross negligence on the part of the Company or its employees. <br /> 6.
              Members are responsible for any damage to their computer systems or loss of data and information resulting
              from downloading or accessing specific programs or information through the Company's services. <br />
              7. The Company is not obligated to intervene in disputes between Members or between a Member and a third
              party that arise through the service. The Company is exempt from liability for any damages resulting from
              such disputes unless there is intentional or gross negligence on the part of the Company. <br /> 8. The
              Company is not liable if a Member fails to obtain the expected benefits from using the service, nor is the
              Company liable for any damages resulting from the selection or use of the service.
            </li>
          </ul>
          <ul>
            <li>
              <strong> Article 30 [Governing Law and Jurisdiction]</strong>
            </li>
            <li>
              1. If a dispute arises between the Company and a Member regarding service usage, the Company and the
              Member must sincerely negotiate to resolve the dispute. If a resolution cannot be reached, either party
              may apply for dispute mediation with the Content Dispute Mediation Committee under the "Content Industry
              Promotion Act." <br /> 2. If the dispute is not resolved through mediation, either party may file a
              lawsuit. The jurisdiction for any lawsuit between the Company and the Member shall be the Member’s address
              at the time of filing, or the Company’s headquarters if the Member’s address is unclear. In cases where
              the address or residence is not apparent, the court jurisdiction will be determined according to the Civil
              Procedure Act.
            </li>
          </ul>

          <ul>
            <li>
              <strong>[Addendum]</strong>
            </li>
            <li>
              1. The revised terms and conditions will be effective from August 9, 2024, and will replace the previous
              terms.
            </li>
          </ul>
        </div>
      </main>
    );
  } else {
    return (
      <main className="w-full h-full overflow-y-auto no-scrolbar bg-N0">
        <div className="my-5 mx-[1rem] leading-7">
          <h1>
            <strong>
              사운드필이용약관 <br />
            </strong>
          </h1>
          <h1 className="text-">
            <strong>
              제장총칙 <br /> 제조목적
            </strong>
          </h1>
          <p className="leading-7">
            본 약관은 주식회사 디지털뉴트리션(이하 '회사'라고 합니다)이 제공하는 ‘사운드필’의 콘텐츠 서비스(웹, 모바일
            웹•앱 서비스, 구독 서비스 등을 포함합니다) 및 제반 서비스를 이용함에 있어 '회원’과 ‘회사' 간의 권리, 의무 및
            책임사항, 서비스 이용조건 등 기본적인 사항을 규정하는 것을 목적으로 합니다.
          </p>
          <p className="my-2">
            <strong>제2조 [용어 정의]</strong>
          </p>
          <p className="leading-7">
            이 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다. 각 호에 정의된 용어 이외의, 약관 내 용어의 정의에
            대해서는 관계 법령 및 서비스 안내에서 정하는 바에 따릅니다.
          </p>
          <ul className="leading-7">
            <li>
              • 1. 콘텐츠 : 「정보통신망 이용촉진 및 정보 보호 등에 관한 법률」 제2조 제1항 제1호의 규정에 의한
              정보통신망에서 사용되는 부호, 문자, 음성, 음향, 이미지 또는 영상 등으로 표현된 자료 또는 정보로서, 그 보존
              및 이용에 있어서 효용을 높일 수 있도록 물리적 또는 전자적 형태로 제작 또는 처리된 것을 말합니다.
            </li>
            <li>
              • 2. 회사 : ‘콘텐츠’ 산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 서비스(웹, 모바일 웹•앱 서비스 등을
              포함합니다) 및 제반 서비스를 제공하는 자로서 본 약관에서는 주식회사 디지털뉴트리션을 말합니다.
            </li>
            <li>
              • 3. 회원 : '회사'와 이용계약을 체결하고 '회사'가 공지하는 정보를 수령하며 '회사'가 제공하는 서비스를
              이용할 수 있는 자를 말하고 원칙적으로 ‘기업회원’을 포함합니다.
            </li>
            <li>
              • 4. 기업회원 : '회사'는 기업 또는 단체와 별도 계약을 통해 기업 또는 단체의 임직원 등 개인에게 서비스를
              제공할 수 있으며, 해당 계약 방식을 통해 제공되는 서비스를 이용하는 개인을 기업회원으로 정의합니다.
            </li>
            <li>
              • 5. 계정 : ‘회사’가 ‘회원’ 가입 시 ‘회원’의 동일성, 로그인, 정보변경, 결제정보 등을 관리할 수 있도록
              ‘회원’정보의 식별 목적으로 발급하는 것으로서 문자, 숫자, 특수문자 등의 조합으로 이루어진 정보를 말합니다.
            </li>
            <li>
              • 6. 게시물 : '회원'이 서비스를 이용하는 과정에서 작성한 텍스트, 이미지, 음성, 동영상, 링크 등의 정보나
              자료를 의미합니다.
            </li>
            <li>
              • 7. 내보관함 : 회원이 다운로드한 '콘텐츠'의 리스트 및 '회원'이 작성한 '게시물'을 저장하고 있는 가상의
              공간을 의미합니다.
            </li>
            <li>
              • 8. 닉네임 : 회원 가입 시 ‘회원’에게 부여되는 고유의 명칭으로 자동 부여되며 ‘회원’이 직접 변경할 수
              있습니다.
            </li>
            <li>
              • 9. 유료서비스 : '회사'가 제공하는 서비스 중 '회원'이 '회사'에 일정 금액을 지불하거나, '회원'이 '회사'
              또는 제3자와의 거래 내지 약정 조건을 수락하는 경우에 이용할 수 있는 '회사'의 서비스를 의미합니다. 단,
              '회사' 또는 위 제3자와 연결(링크)된 유료 콘텐츠 제공업체 및 '회사'와 계약을 통하여 입점한 제휴업체의
              서비스를 이용하는 것은 '회사'의 '유료서비스' 이용 행위로 보지 않으며, 본 약관을 적용하지 아니합니다.
            </li>
            <li>
              • 10. 구독회원 : ‘유료서비스’를 이용 중인 ‘회원’을 의미합니다. ‘회사’는 ‘구독회원’과 ‘구독회원’이 아닌
              ‘회원’에게 제공하는 서비스에 차별을 두어 제공할 수 있습니다.
            </li>
            <li>
              • 11. 결제 : ‘회사’가 제공하는 ‘유료서비스’를 이용하기 위하여 각종 지불수단을 통하여 일정 금액을 '회사'
              또는 '회사'가 정한 제3자에 지불하는 것을 의미합니다.
            </li>
            <li>
              • 12. 정기구독 : ‘회원’이 ‘유료서비스’를 이용하기 위해 미리 등록한 결제수단을 통하여 월/년 단위의
              이용기간에 대한 이용요금이 정기적으로 결제되고, 이용기간이 자동 갱신되는 ‘유료서비스’ 이용방식을 말합니다.
              ‘정기구독’을 해지하면 ‘결제’가 이루어진 ‘유료서비스’의 남은 이용기간 동안은 ‘유료서비스’를 정상 이용할 수
              있고, 다음 이용기간부터 자동 결제가 이루어지지 않습니다.
            </li>
            <li>
              • 13. 구독취소 : ‘회원’이 ‘유료서비스’의 ‘결제’를 취소하고 ‘유료서비스’의 이용을 바로 중단하는 것을
              의미합니다. ‘정기구독’을 이용하는 ‘회원’이 ‘구독취소’를 하는 경우 ‘정기구독’ 해지도 자동으로 이루어집니다.
              ‘구독취소’에 따른 환불은 본 이용약관의 규정에 따릅니다.
            </li>
            <li>
              • 14. DRM(Digital Right Management) : 권리관리정보는 다음 각 목의 어느 하나에 해당하는 정보나 그 정보를
              나타내는 숫자 또는 부호로서 각 정보가 저작권, 그 밖에 저작권법에 따라 보호되는 권리에 의하여 보호되는
              저작물 등의 원본이나 그 복제물에 부착되거나 그 공연ㆍ실행 또는 공중 송신에 수반되는 것을 말합니다.
            </li>
            <li className="ml-5">
              • 가. 저작물 등을 식별하기 위한 정보 <br /> • 나. 저작권, 그 밖에 저작권법에 따라 보호되는 권리를 가진
              자를 식별하기 위한 정보 <br /> • 다. 저작물 등의 이용 방법 및 조건에 관한 정보 콘텐츠
            </li>
            <li>
              • 15. 구독권, 쿠폰 또는 상품권 : ‘회사’의 '유료서비스'를 이용할 수 있는 이용권을 말하고 온라인 또는
              오프라인으로 발행할 수 있으며, 사용방법은 '구독권', '쿠폰' 또는 '상품권' 이용안내 페이지 등에 별도로
              안내합니다.
            </li>
            <li></li>
          </ul>
          <p className="my-2">
            <strong>제3조 [법령의 준수]</strong>
          </p>
          <p>
            ‘회사’는 ‘회원’ 에게 ‘콘텐츠’를 제공함에 있어서 「전자상거래 등에서의 소비자보호에 관한 법률」, 「표시·광고
            등의 공정화에 관한 법률」, 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
            「저작권법」, 「개인정보 보호법」, 「청소년 보호법」, 「콘텐츠산업 진흥법」, 문화체육관광부장관이 정하는
            「콘텐츠이용자 보호지침」 등의 관련 법령을 준수합니다.
          </p>
          <p className="my-2">
            <strong>
              제4조 [약관의 해석] 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래 등에서의
              소비자보호에 관한 법률」, 「표시·광고 등의 공정화에 관한 법률」, 「약관의 규제에 관한 법률」, 「정보통신망
              이용촉진 및 정보보호 등에 관한 법률」, 「저작권법」, 「개인정보 보호법」, 「청소년 보호법」, 「콘텐츠산업
              진흥법」, 문화체육관광부장관이 정하는 「콘텐츠이용자 보호지침」, 기타 관계법령 또는 상관례에 따릅니다.
            </strong>
          </p>
          <p>
            <strong>제5조 [약관의 효력 및 변경]</strong>
          </p>
          <ul className="leading-7">
            <li> • ① ‘회사’는 본 약관의 내용을 ‘회원’이 쉽게 알 수 있도록 서비스 페이지에 게시합니다.</li>
            <li>
              • ② ‘회사’는 본 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소, 전화번호, 이메일주소, 사업자등록번호,
              통신판매업 신고번호 및 개인정보관리책임자 등을 ‘회원’이 쉽게 알 수 있도록 서비스 화면에 게시합니다. 약관은
              ‘회원’이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
            </li>
            <li>
              • ③ '회사'는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호에 관한 법률(이하
              '정보통신망법')”, “콘텐츠산업 진흥법”, “전자상거래 등에서의 소비자 보호에 관한 법률”, 문화체육관광부장관이
              정하는 “콘텐츠이용자 보호지침” 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
            </li>
            <li>
              • ④ '회사'가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 제1항의 방식에 따라
              그 개정약관의 적용일자 7일 이전부터 적용일 이후 상당한 기간 동안 공지하며, 개정된 약관은 그 적용 일에
              효력이 발생합니다. 단, 개정 내용이 '회원'에게 불리한 경우에는 그 시행일 30일 전부터 시행일 이후 상당한
              기간 동안 본 서비스 페이지에 게시하고 이메일 또는 휴대전화를 통해 제공하는 등 '회원'이 인지할 수 있는
              합리적으로 가능한 방법으로 공지합니다.
            </li>
            <li>
              • ⑤ '회사'가 전항에 따라 '회원'에게 ‘공지일로부터 개정약관 시행일까지 거부의사를 표시하지 아니하면 승인한
              것으로 본다’는 뜻을 명확하게 공지 또는 고지하였음에도 불구하고 ‘회원’의 거부의 의사표시가 없는 경우에는
              변경된 약관에 동의한 것으로 봅니다. ‘회원’이 개정약관에 동의하지 않을 경우 ‘회원’은 본 이용약관 규정에
              따라 이용계약을 해지할 수 있습니다.
            </li>
            <li>
              • ⑥ 이 약관은 ‘회원’이 이 약관에 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을 원칙으로 합니다. 단, 이
              약관의 일부 조항은 회원 탈퇴 후에도 유효하게 적용될 수 있습니다.
            </li>
            <li>
              • ⑦ ‘회사’는 ‘회원’이 이 약관의 전부를 인쇄할 수 있고, 확인할 수 있도록 필요한 기술적 조치를 취합니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong>제6조 [회원에 대한 통지]</strong>
            </li>
            <li>
              • ① '회사'는 '회원'에게 알려야 할 사항이 있는 경우, ‘회원’이 공개한 이메일주소, 전화 등의 연락처를 통해
              개별 통지하거나, 앱을 통해 팝업메세지, 알림메세지 등의 방법으로 개별 통지할 수 있습니다. 다만, ‘회원’이
              연락처를 미 기재하거나 변경하거나, 앱을 삭제 또는 이용하지 않는 등으로 인하여 개별 통지가 어려운 경우
              ‘회사’의 게시판 등에 7일 이상 게시함으로써 개별 통지를 갈음할 수 있습니다.
            </li>
            <li>
              • ② '회사'는 '회원' 전체에 대한 통지의 경우 7일 이상 '회사'의 게시판에 게시함으로써 전항의 개별 통지를
              갈음 할 수 있습니다. 다만, '회원' 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 전항과
              동일한 방법으로 통지를 합니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong>제7조 [회사의 의무]</strong>
            </li>
            <li>
              • ① '회사'는 법령과 본 약관에 반하는 행위를 하지 않으며, 지속적이고 안정적인 서비스의 제공을 위하여 최선을
              다하여 노력합니다.
            </li>
            <li>
              • ② '회사'는 '회원'이 안전하게 서비스를 이용할 수 있도록 현재 인터넷 보안기술의 발전 수준과 '회사'가
              제공하는 서비스의 성격에 적합한 보안 시스템을 갖추고 운영합니다.
            </li>
            <li>
              • ③ ‘회사’는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이
              불가능할 경우, ‘콘텐츠’의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만, 이 경우 그 사유 및 기간
              등을 ‘회원’에게 사전 또는 사후에 공지합니다.{' '}
            </li>
            <li>
              • ④ '회사'는 서비스를 이용하는 '회원'으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우 이를
              처리하여야 합니다. 다만, ‘회사’는 즉시 처리가 곤란한 경우는 ‘회원’에게 그 사유와 처리일정을 전달합니다.
              이때 처리과정에 대해서 ‘회원’에게 게시판 등의 방법으로 전달합니다.{' '}
            </li>
            <li>• ⑤ ‘회사’는 이 약관에 정한 ‘회사’의 의무를 위반함으로써 ‘회원’이 입게 된 손해를 배상합니다.</li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제8조 [개인정보 보호 및 관리] </strong>
            </li>
            • ① '회사'는 서비스를 제공하기 위하여 필요한 최소한의 '회원'의 개인정보를 수집할 수 있습니다. <br /> • ②
            ‘회사’가 ‘회원’의 개인 식별이 가능한 개인정보를 수집하는 때에는 당해 ’회원’의 동의를 받습니다. <br /> • ③
            ‘회사’는 ‘회원’이 이용신청 등에서 제공한 정보와 제1항에 의하여 수집한 정보를 ‘회원’의 동의 없이 목적 외로
            이용하거나 제3자에게 제공할 수 없으며, 이를 위반한 경우에 모든 책임은 ‘회사’가 집니다. 다만, 다음의 경우에는
            예외로 합니다. <br /> • 1. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수
            없는 형태로 제공하는 경우 <br /> • 2. ’콘텐츠’ 제공에 따른 요금 정산을 위하여 필요한 경우 <br /> • 3. 도용
            방지를 위하여 본인확인에 필요한 경우 <br /> • 4. 약관의 규정 또는 법령에 의하여 필요한 불가피한 사유가 있는
            경우 <br /> • 5. ‘회사’의 서비스를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우{' '}
            <br /> • ④ ‘회원’은 언제든지 제2항 및 제3항의 동의를 임의로 철회할 수 있으나 서비스 제공에 필요한 정보의
            수집 또는 제공 동의를 철회할 경우 서비스의 제공이 제한될 수 있습니다. <br /> • ⑤ ‘회사’는
            “정보통신망이용촉진 및 정보보호에 관한 법률” 등 관계 법령이 정하는 바에 따라 ‘회원’의 개인정보를 보호하기
            위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법령 및 ‘회사’의 개인정보처리방침 등
            개인정보보호정책이 적용됩니다. <br /> • ⑥ '회사'는 '회사'의 귀책 없이 '회원'의 귀책사유로 인하여 '회원'의
            정보가 노출된 경우 이에 대해서는 책임을 지지 않습니다.
          </ul>
          <ul className="my-2">
            <li>
              <strong>제2장 </strong>
            </li>
            <li>
              <strong> 회원 제9조 [회원 가입]</strong>
            </li>
            <li>
              • ① ‘회원’은 '회사'의 서비스 이용을 위해서 디지털뉴트리션 웹/앱 사이트를 통해 휴대폰번호로 회원 가입을 할
              수 있습니다. 다만, 휴대폰번호로 이용이 어려운 경우 회사의 예외 정책에 따라 별도의 회원 가입 수단을 허용할
              수 있습니다. <br /> • ② ‘회원’이 만 14세 미만인 경우에는 부모님 등 법정대리인의 동의 후 회원 가입을 할 수
              있습니다. <br /> • ③ 회원 가입 과정에서 ‘닉네임’이 부여되며, ‘회원’이 가입 도중 혹은 가입 완료 후
              ‘닉네임’을 수정할 수 있습니다. <br /> • ④ '회사'는 ‘회원’이 회원 가입 페이지의 '동의함' 버튼을 클릭하거나
              본 서비스의 이용을 시작한 경우 본 약관에 동의한 것으로 간주하고, 회원 가입신청을 한 후 이러한 신청에
              대하여 승낙함으로써 회원 가입계약이 체결됩니다. <br /> • ⑤ '회원'은 서비스 회원 가입을 완료하기 전에 해당
              ‘유료서비스’와 지원 기기에 대하여 ‘회사’가 공지하는 상황을 사전에 숙지하고, 착오 없이 정확히 거래할 수
              있도록 하여야 합니다. <br /> • ⑥ '회사'는 회원 가입 신청을 완료하였을 때에는 신청 내용을 확인한 후 지체
              없이 이를 승낙하여야 합니다. 단, '회사'는 아래 각 호에 해당하는 경우에는 승낙을 유보하거나 거절할 수
              있으며, 사후에 이용계약을 해지할 수 있습니다. <br /> • 1. 본 약관에 의하여 이전에 회원자격을 상실한 적이
              있는 경우 <br /> • 2. 타인의 명의를 이용한 경우 <br /> • 3. 만 14세 미만의 회원이 법정 대리인 동의 등
              필요한 절차를 완료하지 않거나 허위로 한 경우 <br /> • 4. 허위의 정보를 기재하거나, 회사가 제시하는 내용을
              기재하지 않은 경우 <br /> • 5. ‘회원’의 귀책사유로 인하여 승인이 불가능한 경우 <br /> • 6. 관계 법령, 기타
              규정한 제반 사항을 위반하여 신청하는 경우 <br /> • 7. 기술 및 설비의 미 보유로 인하여 서비스 제공이
              가능하지 않은 경우 <br /> • ⑦ 본 서비스 이용계약은 '회사'의 승낙이 ‘회원’에게 도달한 시점에 성립합니다.{' '}
              <br /> • ⑧ ‘회원’은 가입 완료 후 ‘회원’ 전용‘서재’가 생성됩니다. ‘회원’ 전용‘서재’는 공개 또는 비공개로
              설정할 수 있습니다. ‘회원’이 전용’서재’를 공개로 설정한 경우에는 다른 ‘회원’들이 ‘서재’의 도서리스트 및
              ‘회원’이 작성한 ‘게시물’을 열람할 수 있습니다. <br /> • ⑨ '회사'는 '회원'의 종류에 따라 전문기관을 통한
              실명확인 및 본인인증을 요청할 수 있습니다. <br /> • ⑩ '회사'는 '회원'에 대하여 “영화 및 비디오물의 진흥에
              관한 법률” 및 “청소년 보호법”등에 따른 등급 및 연령 준수를 위해 이용 제한이나 등급 별 제한을 할 수
              있습니다. <br /> • ⑪ '회원'은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수
              있습니다. <br /> • ⑫ '회원'은 회원 가입 시 기재한 사항이 변경되었을 경우 웹/앱으로 수정을 하거나 고객센터
              등을 통해 '회사'에 변경사항을 알려야 합니다. 변경사항을 '회사'에 알리지 않아 발생한 불이익에 대하여
              '회사'는 책임지지 않습니다.
            </li>
          </ul>
          <ul>
            <li>
              <strong> 제10조 [회원의 의무]</strong>
            </li>
            <li>
              • ① '회원'은 정보가 변경되었을 경우 정보변경 메뉴를 통하여 수정하여야 합니다. 변경하지 않아 발생되는
              손해는 ‘회원’ 본인에게 있습니다. <br /> • ② 로그인에 필요한 정보는 ‘회원’ 본인만 사용이 가능하며 타인에게
              공유하지 않습니다. 제3자가 ‘회원’의 정보를 사용하고 있음을 인지한 경우 바로 ‘회사’에 통보하고 ‘회사’의
              안내를 따라야 합니다. <br /> • ③ ’회원’은 ‘회사’에서 제공받은 모든 콘텐츠를 ‘회사’의 사전 동의 없이 복제,
              판매, 편집, 전시, 배포, 방송 등 저작권을 침해하는 일체의 행위를 해서는 안됩니다. <br /> • ④ '회원'은
              관계법령, 이 약관의 규정, 이용지침, 서비스 이용안내 및 서비스와 관련하여 공지한 주의사항, '회사'가
              통지하는 사항 등을 준수하여야 하며, 기타 '회사'의 업무에 방해되는 행위 및 다음 각호에 해당하는 행위를
              해서는 안됩니다. <br /> • 1. 가입 신청 또는 회원정보 변경 시 허위 내용을 등록하는 행위 <br /> • 2. 이벤트
              참여 등의 과정에서 비정상적인 가입을 시도하거나 서비스 제공을 방해하는 행위 <br /> • 3. 타인의 정보를
              수집, 저장, 공개하거나 도용하여 부정하게 사용하는 행위 <br /> • 4. 회원의 정보를 회사의 동의 없이 타인에게
              제공하여 타인이 회사의 서비스를 이용하게 하는 행위 및 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게
              양도, 증여하는 행위 <br /> • 5. 저작권법에 위반하여 기술적 보호조치를 무력화하는 불법 프로그램 이용 등
              비정상적인 경로를 통해 서비스를 이용하는 행위 <br /> • 6. 회사의 동의 없이 서비스, 콘텐츠 또는 이에 포함된
              소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여하거나 타인에게 그 이용을 허락하는 행위와
              소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타 변형하는
              행위 <br /> • 7. ‘회사’의 운영자, 임직원, ‘회사’를 사칭하거나 관련 정보를 도용하는 행위 <br /> • 8.
              ‘회사’가 서비스에 게시한 정보를 변경하거나 전송하는 행위 <br /> • 9. ‘회사’와 기타 제3자의 저작권,
              영업비밀, 특허권 등 지적재산권을 침해하는 행위 <br /> • 10. 제공되는 서비스를 사적 목적이 아닌
              공공장소에서 공개하거나 영리를 목적으로 이용하는 행위 <br /> • 11. 자신의 재산상 이익을 위하여 ‘회사’ 또는
              ‘회사’의 서비스와 관련된 허위의 정보를 유통하거나 비정상적으로 서비스를 이용하는 행위 <br /> • 12.
              ‘회사’와 다른 ‘회원’ 및 기타 제3자를 희롱하거나, 위협하거나 명예를 손상시키는 행위 <br />• 13. 외설,
              폭력적인 메시지, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위 <br /> • 14. 고객센터 문의 시 욕설,
              폭언, 성희롱, 반복적인 민원을 통해 업무를 방해하는 행위 <br /> • 15. 현행 법령에 위반되는 불법적인 행위{' '}
              <br /> • 16. 기타 ‘회사’의 건전한 서비스 운영을 방해하는 일체의 행위 <br /> • ⑤ '회사'는 '회원'이 전항에서
              금지한 행위를 하는 경우, 위반 행위의 경중에 따라 서비스의 이용정지/계약의 해지 등 서비스 이용 제한, 수사
              기관에의 고발 조치 등 합당한 조치를 취할 수 있습니다. <br /> • ⑥ '회원'은 '회사'의 사전 허락 없이 '회사'가
              정한 이용 목적과 방법에 반하여 영업/광고활동 등을 할 수 없고, '회원'의 서비스 이용이 '회사'의 재산권,
              영업권 또는 비즈니스 모델을 침해하여서는 안됩니다. <br /> • ⑦ 청소년 유해 매체물을 이용하기 위해서는 만
              19세 이상의 회원이어야 하며(단, 만 19세에 도달하는 해의 1월 1일을 맞이한 자를 포함합니다), ”정보통신망
              이용촉진 및 정보보호 등에 관한 법률” 및 “청소년 보호법”의 규정에 의한 인증 수단을 통해 성인인증을 받아야
              하고 성인인증을 받지 않는 경우, 해당 서비스의 이용이 제한됩니다. <br /> • ⑧ ‘회사’는 ‘회원’이 본 조에서
              규정한 의무를 위반하여 발생한 일체의 문제에 대해서는 책임을 지지 않습니다. 단, ‘회원’의 의무위반에 회사의
              책임이 있는 경우 그러하지 아니합니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제11조 [정보의 제공 및 광고의 게재]</strong>
            </li>
            <li>
              • ① '회사'는 서비스 운영과 관련하여 ‘회원’이 입력한 정보를 활용하여 광고를 게재할 수 있습니다. '회원'은
              서비스 이용 시 노출되는 맞춤 광고 게재에 대해 동의합니다. <br /> • ② ‘회사’는 서비스 이용 중 필요하다고
              인정되는 다양한 정보를 홈페이지, 콘텐츠 화면, 공지사항 등의 방법으로 ‘회원’에게 제공할 수 있고, ‘회원’의
              사전 동의를 얻은 경우 이메일 등의 방법으로 제공할 수 있습니다. 다만, ‘회원’은 언제든지 ‘회사’에게 이메일
              등을 통하여 수신거절을 할 수 있습니다. <br /> • ③ ‘회사’는 서비스상에 게재되어 있거나 서비스를 통한
              광고주의 판촉활동에 ‘회원’이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지
              않습니다. 단, 광고주의 판촉 활동에 허위사실의 게재 등 불법행위가 있음을 회사가 알았거나 알 수 있었음에도
              중대한 과실로 알지 못 한 경우에는 그러하지 아니합니다. <br /> • ④ '회원'은 관련 법령에 따른 거래 관련 정보
              및 고객 문의 등에 대한 답변 등을 제외하고는 언제든지 관리 메뉴를 통해 광고의 수신거절을 할 수 있습니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제12조 [회원탈퇴 및 회원자격의 정지]</strong>
            </li>
            <li>
              • ① '회원'은 '회사'에 언제든지 회원탈퇴를 요청할 수 있으며, '회사'는 즉시 회원가입 계약해지 요청을
              처리합니다. <br /> • ② ‘회원’은 회원탈퇴 시 기존에 구매했거나 다운로드 했던 ‘콘텐츠’, 작성한 ‘게시물’,
              다양한 형태의 포인트 등 이용이 불가합니다. 이에 대하여 ‘회사’가 보존 방법 및 삭제를 안내하였음에도
              불구하고 ‘회원’이 개인 보존 등 조치를 취하지 아니한 경우 ‘회사’에서 책임을 지지 않습니다. <br /> • ③
              '회사'는 '회원'이 다음 각 호의 사유에 해당하는 경우, 회원탈퇴 또는 회원자격을 정지시킬 수 있습니다. <br />{' '}
              • 1. 가입 신청 또는 '콘텐츠' 이용 신청 또는 변경 시 허위 내용을 등록한 경우 <br /> • 2. 다른 사람의 서비스
              이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우 <br /> • 3. 타인의 정보 등 부정한
              방법으로 가입한 것이 확인된 경우 <br /> • 4. '회사'가 금지한 정보(컴퓨터 프로그램 등)의 사용, 송신 또는
              게시하는 경우 <br /> • 5. '회사' 또는 제3자의 저작권 등 지식 재산권을 침해하는 경우 <br /> • 6. '회사'
              또는 제3자의 명예를 손상시키거나 업무를 방해하는 경우 <br /> • 7. '회사'의 서비스를 이용하는 과정에서 법령
              또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우 <br /> • 8. '회사'의 기술적 보호조치를 회피
              혹은 무력화하는 행위를 하는 경우 <br /> • 9. '회사'가 제공하는 서비스를 정상적인 용도 이외 또는 부당한
              방법으로 이용하는 경우 <br /> • 10. '회사'의 영업을 방해할 목적으로 서비스를 비정상적으로 악용하는 경우{' '}
              <br /> • 11. 본 약관 제10조 4항의 각 호 사항을 위반한 경우 <br /> • ④ '회사'가 '회원' 자격을 제한/정지시킨
              후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우에는 '회사'는 '회원'
              자격을 상실하게 할 수 있습니다. <br /> • ⑤ '회사'가 '회원' 자격을 상실시키는 경우 ‘회원’ 등록을
              말소합니다. 이 경우 '회원'에게 이를 통지하고, '회원' 등록 말소 전에 최소한 30일 이상의 기간을 정하여
              ‘회원’에게 소명할 기회를 부여하며, ‘회원’이 소명한 내용이 정당한 경우 '회사'는 즉시 '회원'의 자격을
              복구합니다. 단, '회사'가 정한 소명 기간 동안 소명하지 않은 경우 ‘회원’ 등록 말소 동의로 간주합니다.
            </li>
          </ul>
          <ul className="leading-7 my-2">
            <li>
              <strong> 제3장</strong>
            </li>
            <li>
              <strong>서비스 제13조 [서비스의 제공 및 이용]</strong>
            </li>
            <li>
              • ① '회사'는 '회원'의 가입신청을 승낙한 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우에는 지정된
              일자부터 서비스를 개시하며, '유료서비스'의 경우 결제가 완료된 후 서비스를 제공합니다. <br /> • ② '회사'는
              '회원'에게 서비스 체험 등의 목적으로 무료 이용 기간을 제공할 수 있습니다. <br /> • ③ 서비스 이용시간은
              '회사'의 업무상 또는 기술상 불가능한 경우를 제외하고는 연중무휴 1일 24시간(00:00-24:00)으로 함을 원칙으로
              합니다. <br /> • ④ '회원'은 1개의 계정으로 최대 5개의 디바이스 (PC, 태블릿, 스마트 폰 등)에서 서비스
              이용이 가능하며, 서비스를 동시 접속하여 사용할 수 는 없습니다. 이 조항은 회사의 사정에 따라 변경할 수
              있습니다. <br /> • ⑤ ‘회사’가 제공하는 서비스와 이 서비스를 통해 이용하는 모든 콘텐츠는 개인적, 비상업적
              용도로만 사용해야 하며, 비상업적인 용도이더라도 회사의 명시적인 동의가 없는 한 타인과 공유해서는 안됩니다.
              또한 ‘회원’은 대중 공연 및 영리 목적으로 서비스를 이용해서도 안 됩니다. <br /> • ⑥ ‘회사’는 안정적인
              서비스 제공과 저작권 보호를 위해 비정상적인 도서 다운로드 횟수가 발견되거나 기술적 보호조치를 무력화하는
              등 불법 프로그램 사용이 감지되는 등의 비정상적인 상황이 발생되었을 경우, 사전 고지없이 ‘회원’의 도서
              다운로드 횟수를 제한할 수 있습니다. <br /> • ⑦ '회사'는 서비스의 일부 또는 전부를 '회사'의 사업 계획 및
              서비스 운영정책에 따라 수정•변경 및 중단할 수 있으며, 이에 대하여 관련 법령에 특별한 규정이 없는 한
              '회원'에게 별도의 보상을 하지 않습니다. 다만, '회원'에게 불리한 것으로 판단되는 경우 전화, 이메일주소,
              앱내 알림 등을 통하여 이를 공지합니다. <br /> • ⑧ '회원'이 유/무선 네트워크를 통하여 '회사'의 사이트에
              접속하거나, 유/무선 네트워크가 연결된 상태의 기기 내에 탑재된 '회사'의 어플리케이션을 통하여 제반 서비스를
              이용하는 경우 및 해외에서 '회사'의 제반 서비스를 이용하게 되는 경우에는 '회원'이 가입한 해당 통신사와
              ‘회원’과의 사이에 체결된 통신 요금제에 의한 별도의 데이터 통화료가 발생됩니다. 데이터 통화료는
              ‘유료서비스’와는 별도이며 데이터 통화료에 대해서는 '회사'는 어떠한 책임도 지지 않습니다. <br /> • ⑨
              '회사'는 서비스의 이용에 관하여 본 약관 외에 ‘게시물’ 등의 관리정책을 별도로 둘 수 있습니다. 이를 통해
              ‘게시물’ 또는 기타 게재된 내용이 서비스에 의해 보유되는 최대 일수 및 용량, 최대 접속 기기수 등을 제한할 수
              있습니다. '회사'의 귀책사유 없이 서비스에 보관되는 ‘게시물’ 또는 기타 게재된 내용 등을 삭제하거나 저장하지
              못한데 대해 '회사'는 아무런 책임을 지지 않습니다. <br /> • ⑩ '회사'는 본 약관에 따른 서비스의 제공 지역을
              대한민국으로 한정할 수 있습니다. <br />• ⑪ '기업회원'의 경우 교육 이수 시간 등의 확인을 위해 기업 또는
              단체의 요청이 있을 시, 해당 담당자에게 '회사'는 도서 열람 정보를 제공할 수 있습니다.
            </li>
          </ul>

          <ul className="leading-7">
            <li>
              <strong> 제14조[계약의 갱신 및 정기결제] </strong>
            </li>
            <li>
              • ① ‘회사’는 ‘콘텐츠’의 무료이용기간이 만료하여 유료로 전환하는 경우, 사전에 결제 페이지에 상세히 고지하며
              ‘회원’의 동의를 받습니다. <br /> • ② ‘회사’는 ‘유료서비스’ 계약을 갱신하고자 하는 경우, 사전에 결제
              페이지에 상세히 고지하며 ‘회원’에게 동의를 받습니다. <br /> • ③ 사업자가 제1항 또는 제2항에 따라 ‘회원’의
              동의를 받지 못한 경우 당해 ‘콘텐츠’ 이용계약은 무료이용기간의 경과 또는 계약기간의 만료로 소멸합니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제15조 [서비스 이용의 변경, 중지 및 피해구제 등]</strong>
            </li>
            <li>
              • ① ‘콘텐츠’의 정상적인 이용이 곤란한 중대한 하자가 있는 경우에 있어서 ‘회원’의 피해구제 등은 다음 각호에
              의합니다. <br /> • 1. ‘회사’는 완전한 ‘콘텐츠’를 다시 공급합니다. <br /> • 2. ‘회사’는 24시간 내에 완전한
              ‘콘텐츠’를 다시 공급하지 못하는 경우, ‘회원’이 지급한 대금의 한도 내에서 손해배상액을 지급합니다. <br />•
              ② ‘콘텐츠’의 정상적 이용이 가능하지만, 사소한 하자가 있는 경우에 있어서 ‘회원’의 피해구제 등은 다음 각호에
              의합니다. <br /> • 1. ‘회사’는 완전한 ‘콘텐츠’를 다시 공급합니다. <br /> • 2. ‘회사’는 24시간 내에 완전한
              ‘콘텐츠’를 다시 공급하지 못하는 경우, ‘회원’이 지급한 대금 중 하자에 해당하는 부분에 대한 금액을
              손해배상액으로 지급한다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제16조 [콘텐츠의 이용환경 제한 고지 및 다운로드, 재생 제한 등]</strong>
            </li>
            <li>
              • ① ‘회원’은 ‘회사’가 제공하는 사운드필 앱을 통해서만 콘텐츠를 다운로드하고 다운로드된 콘텐츠는 앱을
              통해서만 재생해야 하며, 비정상 경로를 통해 이용하는 경우 ‘회사’는 ‘회원’에게 서비스 이용 제한 및 발생하는
              모든 피해에 대한 손해 배상 등을 청구할 수 있습니다. <br /> • ② ‘회원’은 ‘유료서비스’ 이용 기간 중에는
              이용이 허용된 '콘텐츠' 파일을 재 다운로드를 하거나 다운로드 받은 파일에 대한 재생이용기간을 연장할 수
              있습니다. <br /> • ③ ‘회원’은 ‘유료서비스’ 이용을 위해 다운로드 받은 ‘콘텐츠’ 파일의 재생 이용은 해당
              ‘유료서비스’ 이용 기간 중에만 가능합니다. 해당 ‘유료서비스’ 이용 기간이 경과되거나, 이용료를 결제하지
              않거나 구독 서비스를 취소하는 등의 이유로 해당 ‘유료서비스’ 이용이 해지되면, 이미 다운로드 받은 '콘텐츠'
              파일도 재생 이용할 수 없습니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong>제17조 [권리의 귀속 및 저작물의 이용]</strong>
            </li>
            <li>
              • ① ‘회원’은 서비스를 이용하는 과정에서 ‘회원’이 작성한 텍스트, 이미지, 음성, 동영상, 링크 등의 기타
              정보(이하 ‘게시물’)에 대한 저작권을 보유하며 해당 ‘게시물’이 타인의 지적 재산권을 침해하여 발생하는 모든
              책임 또한 ‘회원’ 본인에게 귀속됩니다. 다만, ‘회사’가 ‘회원’의 게시물이 타인의 지적재산권을 침해함을
              알았거나 쉽게 알 수 있었음에도 주의를 게을리하여 ‘게시물’로 인한 타인의 손해를 방지하지 못하였을 경우
              ‘회원’과 함께 그 타인에 대한 손해배상책임을 질 수 있습니다. <br /> • ② ‘회사’는 ‘회원’이 작성한 ‘게시물’에
              대하여 관련 법령에서 정하는 바에 따라 다음과 같은 방법과 조건으로 이용할 수 있습니다. 단, 본 항에서 정하지
              않은 방법과 조건으로 ‘게시물’을 이용하고자 할 경우에는 ‘회원’의 이용허락을 받아야 합니다. <br /> • 1.
              서비스 프로모션을 위해 필요한 범위에서의 ‘회원’의 ‘게시물’의 활용(‘회사’ 서비스 내 노출, 서비스 홍보를
              위한 활용, 서비스 운영 및 콘텐츠 홍보물 제작 등을 위하여 이용자 게시물을 편집∙수정하여 활용하거나 전자책
              및 종이책 출판 시 표지 등에 활용하는 방법 등) <br /> • 2. 서비스의 개선 및 향상을 위해 통계분석 등
              연구목적으로 활용(인공지능 분야 기술, 콘텐츠 기획, 이용자 환경 개선 등을 위한 통계자료로서 활용하는 방법
              등) <br /> • ③ ‘회원’이 등록한 ‘게시물’이 “저작권법” 등 관련 법령에 위반되거나, 사생활 침해, 명예훼손 등
              타인의 권리를 침해한다고 인정되는 경우 ‘회사’는 해당 ‘게시물’에 대한 접근을 임시적으로 차단하거나 삭제할
              수 있습니다. <br /> • ④ '회사'가 작성하고 제공하는 모든 ‘콘텐츠’에 대한 저작권은 ‘회사’에 있습니다. <br />{' '}
              • ⑤ '회사'는 '회원'과 별도로 서면 계약을 체결하여 서비스의 브랜드 특성을 이용할 수 있는 명시적인 권리를
              부여하지 아니하는 한, '회원'에게 '회사' 또는 서비스의 상호, 상표, 서비스표, 로고, 도메인 네임 및 기타
              식별력 있는 브랜드 특성을 이용할 수 있는 권리를 부여하지 않습니다. <br /> • ⑥ '회원'이 서비스에 ‘게시물’을
              게재하는 것은 다른 '회원'이 ‘게시물’을 서비스 내에서 사용하거나 '회사'가 검색결과로 사용하는 것을 허락한
              것으로 봅니다. 다만, '회원'은 서비스 내의 관리기능을 통하여 ‘게시물’의 공개 옵션 설정 등의 조치를 취할 수
              있습니다. <br /> • ⑦ '회사'는 서비스 정책 또는 '회사'가 운영하는 서비스 간 통합 등의 사유로 ‘게시물’의
              게재 위치를 변경•이전할 수 있으며, 이 경우 사전에 공지합니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제18조 [프로그램 설치 및 데이터 수집]</strong>
            </li>
            <li>
              • ① ‘회사’는 서비스를 제공함에 있어 필요한 경우 ‘회원’에게 어플리케이션 및 별도의 프로그램을 설치 및
              업데이트하도록 안내할 수 있습니다. 다만, ‘회원’은 원하지 않거나, 기기가 서비스를 지원하지 못하는 경우 그
              설치 및 업데이트를 거부할 수 있으나 서비스 이용이 제한될 수 있습니다. <br /> • ② ‘회사’에서 제공한
              프로그램은 서비스를 사용하고 혜택을 누릴 수 있도록 하기 위한 목적으로만 제공되며, 이 프로그램의 어느
              부분도 복사, 수정, 배포, 판매, 대여할 수 없습니다. 다만, ‘회원’이 법률상 근거를 가지거나 ‘회사’에서 승인을
              받은 경우는 해당 사항에 한해 허용될 수 있습니다. <br /> • ③ ‘회사’는 ‘회원’의 사전 동의를 받아 서비스 이용
              기기의 데이터를 수집하고 이용할 수 있습니다. 다만, ‘회사’는 수집한 정보를 서비스 개선 및 적합한 서비스
              제공을 하기 위한 목적으로만 사용할 수 있고, 그 외의 다른 목적으로 사용하지 않습니다. <br /> • ④ ‘회사’는
              '회원'의 스마트폰 앱 설치 시 사전 동의를 받아 다음 각호의 기능에 한해 ‘회원’의 스마트폰에 접근할 수
              있습니다. <br /> • 1. 전화 : 오디오북 또는 TTS 모드 이용 도중 다른 미디어 앱 사용시 음성 제어를 위해
              필요한 권한 <br /> • 2. 저장공간 : 콘텐츠를 저장하고 이후 지속적으로 열람할 수 있도록 하기 위해 필요한
              권한
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제19조 [게시물의 관리]</strong>
            </li>
            <li>
              • ① '회원'이 서비스 내에 작성한 '게시물'에 대한 책임 및 권리는 ‘게시물’을 등록한 '회원'에게 있습니다.{' '}
              <br /> • ② ‘회사’는 ‘회원’이 작성한 ‘콘텐츠’에 대해서 감시 및 관리할 수 없으며 이에 대해서 책임지지
              않습니다. 단, 다른 ‘회원’의 문제제기 등으로 ‘회원’이 작성한 ‘콘텐츠’의 내용에 불법적인 내용이 포함되어
              있음을 알고도 상당한 기간 내에 합리적인 조치를 취하지 않은 경우에는 그러하지 아니합니다. <br /> • ③
              ‘회사’는 ‘회원’이 등록하는 ‘게시물’의 신뢰성, 진실성, 정확성 등에 대해서 책임지지 않으며 보증하지
              않습니다. 단, 다른 ‘회원’의 문제제기 등으로 ‘회원’이 작성한 ‘콘텐츠’의 내용에 허위 등의 내용이 포함되어
              있고, 이로 인하여 제3자에게 피해를 줄 가능성이 있음을 알고도 상당한 기간 내에 합리적인 조치를 취하지 않은
              경우에는 그러하지 아니합니다. <br /> • ④ '회원'의 ‘게시물’이 “정보통신망 이용촉진 및 정보보호 등에 관한
              법률” 및 “저작권법” 등 관련 법령에 위반되는 내용을 포함하는 경우, 권리자는 관련법령이 정한 절차에 따라
              해당 “게시물”의 게시중단 및 삭제 등을 요청할 수 있으며, '회사'는 관련법에 따라 조치를 취할 수 있습니다.
              <br /> • ⑤ '회사'는 다음 각 호와 같은 내용의 ‘게시물’이 서비스 내에 게재되는 경우 제한할 수 있습니다.
              <br /> • 1. 사생활 침해, 명예훼손, 욕설, 비속어로 타인에게 불쾌감을 주는 경우 <br /> • 2. 공서양속 저해 및
              특정 집단, 단체, 종교 비하로 타인에게 불쾌감을 주는 경우 <br /> • 3. 확인되지 않거나 근거 없는 내용으로
              타인의 권리를 침해하는 경우 <br />• 4. 타인의 저작권을 침해하는 경우 <br /> • 5. 타인의 개인정보가 포함된
              경우 <br /> • 6. 광고/홍보 내용이 포함된 경우
              <br /> • 7. 동일 내용의 게시물을 반복 게재하는 경우 <br /> • 8. 악성코드 등 회사의 원활한 서비스 제공을
              방해하는 경우
              <br /> • 9. 기타 회원의 원활한 서비스 이용에 불건전한 영향을 미칠 수 있다고 판단되는 경우
              <br /> • ⑥ '회원'이 비공개로 설정한 ‘게시물’에 대해서는 ‘회사’를 포함한 다른 사람이 열람할 수 없습니다.
              다만, ‘회사’는 법원, 수사기관이나 기타 행정기관으로부터 정보제공을 요청받은 경우나 기타 법률에 의해
              요구되는 경우에는 ‘회사’를 포함한 이해관계가 있는 다른 사람으로 하여금 해당 ‘게시물’을 열람하게 할 수
              있습니다. <br /> • ⑦ '회원'이 회원 가입 계약을 해지한 경우에는 본인이 작성한 저작물 일체는 삭제될 수
              있습니다. 다만, 저작물이 공동 저작을 통해 작성된 경우에는 공동 저작자의 ‘게시물’ 등에 해당 ‘게시물’이 남을
              수 있고, 제3자에 의하여 보관되거나 무단복제 등을 통하여 복제됨으로써 해당 저작물이 삭제되지 않고 재 게시된
              경우에 대하여 '회사'는 책임을 지지 않습니다.
              <br /> • ⑧ ‘회사’는 다른 '회원'을 보호하고, 법원, 수사기관 또는 관련 기관의 요청에 따른 증거자료로
              활용하기 위해 본 약관 및 관계 법령을 위반한 '회원'이 회원가입 계약 해지 후에도 ‘회사’의 개인정보취급방침상
              보존기간 및 관계 법령에 따라서 '회원'의 아이디 및 회원정보를 보존할 수 있습니다. 제4장 유료서비스 이용 및
              결제
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제20조 [유료서비스와 유료서비스 이용 회원] </strong>
            </li>
            <li>
              • ① ‘회사’가 ‘회원’의 ‘유료서비스’ 이용신청을 승낙한 때(일부 서비스의 경우, 이용 신청 접수 후 사전 공지한
              지정된 일자)로부터 ‘유료서비스’는 개시됩니다. 다만, ‘회사’의 기술적 사유 등 기타 사정에 의하여
              ‘유료서비스’를 개시할 수 없는 경우에는 제3조(약관의 효력 및 변경)의 방법에 따라 ‘회원’에게 사전
              공지합니다. <br /> • ② ‘회사’는 ‘회원’의 ‘유료서비스’ 이용신청이 있게 되는 경우, 해당 ‘회원’에게
              이용신청의 내용을 통지합니다. <br /> • ③ 전항의 통지를 받은 ‘회원’은 의사표시의 불일치 등이 있는 경우
              ‘회사’에 정정 또는 수정을 요청하여야 하며, ‘회사’는 ‘회원’의 요청에 따라 처리하여야 합니다. 다만, ‘회원’이
              이미 대금을 지불한 경우에는 이 약관의 유료결제 해지 등에 관한 규정에 따릅니다. <br /> • ④ ‘회사’는 다음 각
              호와 같은 ‘유료서비스’를 제공하며, ‘회사’의 사정, 기타 제반 여건에 따라 서비스 내용을 추가하거나 변경할 수
              있습니다. <br /> • 1. 정기구독 ‘유료서비스’ : ‘회원’이 ‘유료서비스’를 이용하기 위해 미리 등록한 결제수단을
              통하여 월/년 단위로 이용 요금이 자동으로 결제되고 이용기간이 자동 갱신되는 서비스 <br /> • 2. 기간만료
              ‘유료서비스’ : 이용 가능 기간이 만료되는 시점에 ‘유료서비스’의 이용이 종료되는 서비스 <br /> • 3. 직접판매
              ‘유료서비스’ : ‘회사’가 제공하는 ‘유료서비스’를 ‘회원’이 직접 결제하여 이용하는 방식 <br /> • 4. 대행판매
              ‘유료서비스’ : ‘회사’가 제공하는 ‘유료서비스’를 제3자를 통해 판매하는 방식이며, 판매상품의 이용방식은
              제휴사가 제공하는 ‘유료서비스’ 안내에 따름 <br /> • 5. 기업회원 ‘유료서비스’ : ‘기업회원’이 ‘유료서비스’
              이용을 위해 ‘기업회원’이 소속되고 ‘회사’와 계약을 체결한 기업 또는 단체가 이용 요금을 결제하여 이용하는
              방식. 이 방식에 의한 유료서비스 제공의 경우 본 약관에서 정한 ‘회원’의 이용요금 납부 등의 의무는
              ‘기업회원’이 속한 기업 또는 단체가 부담하는 것으로 해석합니다. <br /> • ⑤ 회원이 이동전화단말기 제조사,
              이동통신사와의 제휴 유료서비스에 가입한 경우, 이동통신요금제의 이용 정지/중단, 타요금제로의 변경, 제휴사의
              멤버쉽 종류 또는 단말기의 변경 등의 사유로 가입 당시 약정한 조건에 부합하지 않게 되는 경우에는 해당
              제휴서비스가 제공하는 요금 감면, 할인 등을 받을 수 없으므로 해당 조치 이전에 이용권/서비스 안내페이지 등
              회사의 고지사항을 사전에 확인하여야 합니다. <br /> • ⑥ '회원'이 ‘내보관함’에 등록한 '콘텐츠'의 저작자와
              '회사'와의 '콘텐츠' 공급계약이 종료되거나 변경되어, '회원'이 해당 ‘콘텐츠’를 더 이상 다운로드하지 못하는
              경우가 발생할 수 있습니다. 다만, 이러한 변경 사항이 발생하는 경우 '회사'는 지체없이 해당 '콘텐츠'의 변경된
              내용에 관한 정보를 제공하여야 합니다. <br /> • ⑦ ‘회사’와 저작권의 권리를 보유하고 있는 개인, 단체,
              법인(‘권리자 등’) 간의 계약의 체결 여부 및 개별 계약의 내용, ‘권리자 등’의 사후 요청에 따라 개별
              ‘유료서비스’ 또는 ‘콘텐츠’ 파일의 이용이 제한되거나 서비스 가능 지역, ‘콘텐츠’ 파일의 속성 등 내용이 사전
              또는 사후 변경될 수 있습니다. <br /> • ⑧ ‘회사’는 ‘권리자 등’의 요청이 있게 되는 경우, 해당 요청에 따라
              ‘유료서비스’를 지원하는 기기의 종류, 회원별 이용 가능 기기 대 수, 동시 접속자 수 등을 변경할 수 있습니다.{' '}
              <br /> • ⑨ ‘회원’의 '유료서비스' 이용이 해지되는 경우, ‘회원’의 ‘내보관함’에 등록된 '콘텐츠'의 정보는 해지
              시점부터 12개월간 보존한 뒤 영구 삭제하며 복구는 불가합니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong>제21조 [유료서비스 이용 계약]</strong>
            </li>
            <li>
              • ① 본 약관에 동의한 '회원'이 ‘유료서비스’에 대한 이용신청을 하고 '회사'가 이를 승낙함(유료서비스의
              ‘구매/결제완료 등’의 표시가 회원에게 절차상 표시된 시점)으로써 ‘유료서비스’ 이용 계약은 성립합니다. <br />{' '}
              • ② '회사'는 ‘회원’의 ‘유료서비스’ 결제 전에 다음 각 호의 사항에 관하여 '회원'이 정확하게 이해하고 실수
              또는 착오 없이 거래할 수 있도록 구매 페이지를 통해 정보를 제공합니다. <br /> • 1. 해당 ‘유료서비스’의
              내용, 가격, 이용기간, 이용방법, ‘구독취소’ 및 ‘정기구독’ 해지 조건 및 그 방법, 환불에 관한 사항 및
              ‘유료서비스’의 선택과 관련한 사항 <br />• 2. 청약철회(환불)가 불가능한 ‘콘텐츠’에 대해 ‘회사’가 취한
              조치에 관련한 내용에 대한 확인 <br />• ③ '회사'는 본 약관 제12조 [회원탈퇴 및 회원자격의 정지]에서 정하는
              사유가 발생하는 경우, '유료서비스' 이용신청을 승낙하지 않거나 승낙을 유보할 수 있습니다. <br /> • ④
              '회원’은 ‘유료서비스’ 이용 신청 시 필요한 제반 사항을 기재할 경우 ‘회원’의 현재의 사실과 일치하는 정보를
              기재하여야 하며, ‘회원’이 이용하고자 하는 ‘유료서비스’의 결제방법의 선택 및 선택한 결제방법에 필요한
              결제정보를 정확히 ‘회사’에 제공하여야 합니다. <br /> • ⑤ ‘회원'은 전항의 해당 사항이 변경될 경우 지체 없이
              ‘회사’가 정한 절차에 따라 변경사항을 고지하고, ‘회사’는 지체없이 변경사항을 반영하여야 합니다. <br />• ⑥
              '회원'은 '회사'가 지정하는 결제수단으로 '유료서비스'를 이용할 수 있으며, 각종 프로모션이나 이벤트 등을
              통하여 발행된 '구독권', '쿠폰' 또는 '회사' 및 '제3자'가 발행한 상품권 등을 이용하여 '유료서비스'를 이용할
              수 있습니다. <br /> • ⑦ '회사'는 '유료서비스'를 이용하는 '회원'의 거래금액에 대하여 내부정책 및 외부
              결제업체(PG사, 은행사, 카드사 등), 기타 관련 법령의 변경에 따라 '회원' 당 월 누적 결제액, 결제한도 등의
              거래한도를 정할 수 있으며, '회원'은 '회사'가 정한 거래한도를 초과하는 범위의 '유료서비스'를 이용하고자 할
              경우 거래한도의 초과로 인하여 '유료서비스'의 추가 이용이 불가능할 수 있습니다. <br />• ⑧ '회사'가 제공하는
              상품 중 각 저작권자들의 이용허락 여부에 따라 사전 고지 없이 제공되는 시기가 달라질 수 있습니다. <br /> • ⑨
              ‘회원’이 ‘회사’로부터 제공받아 보유하고 있는 이벤트성 마일리지의 경우는 현금으로 환급되지 않으며, '회원'이
              회원 가입 계약을 해지하거나 '회원'자격에 제한을 받을 경우 및 제공받은 날로부터 30일이 경과하면 자동으로
              소멸됩니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제22조 [미성년자 이용계약에 관한 특칙]</strong>
            </li>
            <li>
              만 19세 미만의 미성년 ‘회원’의 결제는 원칙적으로 보호자의 명의 또는 보호자 동의하에 이루어져야 하고,
              보호자는 본인 동의없이 체결된 자녀(미성년자)의 계약을 취소할 수 있습니다. 단, 미성년 ‘회원’이 다른 성년자
              등의 주민등록번호를 이용하여 결제정보를 동의 없이 이용하는 등 사술로써 ‘회사’로 하여금 성년자인 것으로
              믿게 한 경우에는 법정대리인의 동의가 없더라도 계약을 취소하지 못합니다
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제23조 [실물 쿠폰 등의 재화 상품 판매]</strong>
            </li>
            <li>
              • ① '회사'는 ‘회원’에게 도서 등의 재화 상품을 판매할 수 있습니다. <br /> • ② ’회원’은 다음 각 호의 방법에
              의하여 구매를 신청하며, ‘회사’는 ‘회원’이 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야
              합니다. 다만, ‘회사’는 제2호 내지 제4호를 적용하지 않을 수 있습니다. <br /> • 1. 재화 등의 검색 및 선택{' '}
              <br /> • 2. 받는 사람의 이름, 주소, 전화번호, (또는 휴대전화번호) 등의 입력 <br /> • 3. 약관내용,
              구독취소가 제한되는 서비스, 배송료•설치비 등의 비용부담과 관련한 내용에 대한 확인 <br /> • 4. 이 약관에
              동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭) <br /> • 5. 재화 등의 구매신청 및 이에
              관한 확인 또는 '회사'의 확인에 대한 동의 <br /> • 6. 결제방법의 선택 <br /> • 7. 기타 1 내지 6호의 방법과
              유사한 방법 <br />• ③ '회사'는 '회원'이 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별
              배송기간 등을 명시합니다. 다만, ‘회사’는 수단별 배송기간에서 공휴일 및 기타 휴무일 또는 천재지변 등의
              불가항력적 사유가 발생하는 경우 그 해당 기간을 배송소요기간에서 제외하고, 그 외의 사유로 수단별 배송기간을
              초과한 경우에는 지체없이 사유를 고지하여야 합니다. <br /> • ④ '회사'는 '회원'이 구매 신청한 재화의 품절
              등의 사유로 재화의 인도를 할 수 없을 때에는 지체없이 그 사유를 ‘회원’에게 통지하고, 사전에 재화의 대금을
              받은 경우 '회원'이 대체 재화의 구매를 포기하고 환불을 요청할 시 '회원'이 그 대금의 전부 또는 일부를 지급한
              날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 합니다. <br /> • ⑤ '회원'은 “전자상거래 등에서의
              소비자보호에 관한 법률” 제17조에 따라 재화를 배송 받은 날로부터 7일 이내에 반품 또는 교환을 요청할 수
              있습니다. 다만, '회원'은 다음 각 호의 경우에는 반품이나 교환을 요청할 수 없습니다. <br /> • 1. '회원'의
              책임이 있는 사유로 재화 등이 멸실되거나 훼손된 경우 <br /> • 2. '회원'의 사용 또는 일부 소비로 재화 등의
              가치가 현저히 감소한 경우 <br /> • 3. 복제가 가능한 재화 등의 포장을 개봉하거나 훼손한 경우 <br /> • 4.
              기타 재화 등의 특성상 환불이나 교환을 요청할 수 없는 합리적인 사유가 있는 경우 <br />• ⑥ '회원'은 다음 각
              호의 경우에 재화 등의 수령일부터 30일 이내에 환급, 반품 및 교환을 요구할 수 있고, 반품 비용 일체를 ‘회사’
              또는 ‘판매자’가 부담합니다. <br /> • 1. 재화 등의 내용이 표시, 광고 내용과 다르거나 계약내용과 다르게
              이행된 경우. 다만, 이 경우에는 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내 (늦어도 수령일로 부터
              3개월 이내) 환불을 요청할 수 있음 <br />• 2. 배송된 재화가 ‘회원’의 과실 없이 파손, 손상되었거나
              오염되었을 경우 <br /> • 3. 재화가 광고에 표시된 배송기간보다 늦게 배송된 경우 <br /> • ⑦ '회원'은 전항에
              해당하지 않는 사유로 배송 된 재화를 반환하는 경우 반품 비용 일체를 부담해야 합니다. <br /> • ⑧ 다음 각호의
              경우에는 '회원'의 단순한 변심∙착오로 인한 취소, 반품, 교환이 불가능합니다. <br /> • 1. ‘회원’이 개봉한
              굿즈 <br /> • 2. 기타 상품 정보와 함께 변심 반품, 취소가 불가능함을 사전에 표시한 상품 <br />• 3. 구매,
              수령 시의 상태가 보전되지 못하여 반품 후 재판매가 불가능한 상품(래핑 제거, 케이스 폐기, 부록 폐기 등)
              <br /> • ⑨ '회사'는 '회원'이 반환한 재화를 인수받은 후 환급절차를 진행하며, 타 배송업체나 우체국을 이용한
              반환과정에서 분실된 재화에 대해서는 환급하지 아니 합니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제24조 [유료서비스의 변경]</strong>
            </li>
            <li>
              • ① '회사’는 운영상, 기술상의 필요에 따라 공급하는 ‘유료서비스’ 가액을 변경할 수 있으며, 제5조(약관의 효력
              및 변경)의 방법에 따라 ‘유료서비스’ 이용 ‘회원’에게 사전공지 합니다. <br />• ② ‘회원’이 이미 구매/가입한
              ‘유료서비스’는 ‘회사’가 정한 정책에 따라 변경 신청을 할 수 있으며, 즉시 변경 혹은 1개월 예약 변경을 할 수
              있습니다. <br /> • ③ ‘회원’이 전항의 즉시 변경을 선택한 경우, ‘회원’이 변경 요청한 서비스로 즉시
              교체됩니다. 다만, ‘회사’는 ‘회원’의 기존 구매/가입한 서비스의 남은 기간과 변경 요청한 서비스의 가격을 비례
              배분하며, 이용 기간을 조정하며 새로운 결제 주기를 적용합니다. <br /> • ④ ‘회원’이 제2항의 예약 변경을
              선택한 경우, ‘회원’이 변경 요청한 달의 이용기간이 만료되는 날까지 기존 구매/가입한 서비스를 이용할 수
              있으며, 이용기간 만료일 다음날부터 변경 요청한 서비스로 변경 적용됩니다. <br /> • ⑤ 애플 및 구글
              IAP(In-App-Purchase)을 통해 결제되는 ‘유료서비스’는 애플 및 구글 각사의 정책에 따라 수수료를 감안하여 다른
              가격으로 판매될 수 있습니다. <br /> • ⑥ ‘회사’가 제휴를 통해 ‘회원’에게 ‘유료서비스’를 제공하는 경우 해당
              제휴사의 정책을 따릅니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제25조 [유료서비스 이용에 관한 해제∙해지]</strong>
            </li>
            <li>
              • ① ‘정기구독’ 방식의 ‘유료서비스’는 관리메뉴, 고객센터 등을 통해 ‘정기구독’을 해지할 수 있습니다.
              ‘회원’이 ‘정기구독’을 해제∙해지했을 경우, 다음 이용기간부터 ‘유료서비스’에 대한 자동 결제가 이루어 지지
              않습니다. 이미 ‘결제’가 이루어진 ‘유료서비스’의 남은 이용 기간 동안은 ‘유료서비스’를 정상 이용할 수
              있습니다. <br /> • ② '회원'이 디지털 콘텐츠를 다운로드(다운로드 시작 포함)하거나 실시간 스트리밍 서비스를
              통해 열어본 경우를 제외하고는 ‘유료서비스’의 ‘구독취소’를 요청할 수 있습니다. ‘구독취소’ 시 ‘유료서비스’
              결제 금액의 결제일로부터 7일 전에는 100%, 결제일로부터 7일이 지난 경우에는 90%에 해당하는 금액을 환불받을
              수 있습니다. ‘정기구독’ 방식의 ‘유료서비스’에 가입한 경우에도 제1문이 동일하게 적용됩니다. <br /> • ③
              ‘회원'은 재화 등의 내용이 표시, 광고 내용과 다르거나 계약내용과 다른 재화가 제공된 경우, 그 사실을 안 날
              또는 알 수 있었던 날부터 30일 이내에 환불을 요청할 수 있습니다. <br /> • ④ 제1항 내지 제2항의 경우에
              '회원'은 언제든지 앱 내 관리페이지, 고객센터 전화/1:1문의 등을 통해서 ‘구독취소’ 및 ‘정기구독’ 해지를
              신청할 수 있으며 '회사'는 관계 법령 등에서 정하는 절차에 따라 이를 신속하게 처리합니다. <br /> • ⑤
              '회원'이 구매한 재화 또는 서비스를 이용한 이후에 중도 해지 또는 환불을 요청할 경우 환불이 불가합니다.{' '}
              <br /> • ⑥ ‘회사’는 ‘회원’의 ‘구독취소’를 확인 후 환불 금액이 있을 경우, 원칙적으로 ‘회원’의 해당
              의사표시를 수령한 날로부터 3영업일 이내에 결제수단 별 사업자에게 대금의 청구 정지 내지 취소를 요청하고,
              ‘회원’이 결제한 동일 결제수단으로 환불함을 원칙으로 합니다. 단, ‘회사’가 사전에 ‘회원’에게 공지한 경우 및
              아래의 각 경우 등에는 개별 결제 수단별 환불 방법, 환불 가능 기간 등이 차이가 있을 수 있습니다. <br /> • 1.
              신용카드 등 수납 확인이 필요한 결제수단의 경우에는 수납 확인일로부터 3영업일 이내 <br /> • 2. 결제수단 별
              사업자가 ‘회사’와의 약정을 통하여 사전에 대금 청구 정지 내지 결제 취소 가능 기한 등을 미리 정하여 둔
              경우로 해당 기한을 지난 환불의 경우 <br /> • 3. ‘회원’이 ‘유료서비스’/구독권 등을 이용한 후 이용으로 얻은
              이익이 있거나 중도 해지하는 경우 <br />• 4. ‘회원’이 환불 처리에 필요한 정보 내지 자료를 ‘회사’에 즉시
              제공하지 않는 경우(예: 현금 환불 시 신청인의 계좌 및 신분증 사본을 제출하지 아니하거나, 타인 명의의 계좌를
              제공하는 경우 등) <br /> • 5. 해당 ‘회원’의 명시적 의사표시가 있는 경우 <br />• ⑦ ‘회사’는 ‘회원’이
              ‘유료서비스’를 선물 받거나, 프로모션 등을 통해 무료/무상으로 취득하는 등 ‘회원’이 직접 비용을 지불하지
              아니하고 이용하는 ‘유료서비스’에 대하여 ‘회원’에게 ‘유료서비스’ 결제 대금을 환불할 의무를 부담하지
              않습니다. <br /> • ⑧ '회사'가 제12조 [회원탈퇴 및 회원자격의 정지]에 따라 계약을 해지하거나 서비스 이용을
              제한한 후 환불해야 할 금액이 있을 경우에는 본 조에서 정한 환불 기준을 적용하여 공제하고 환불합니다. 다만,
              이 경우 '회원'은 해당 조치에 대하여 '회사'가 정한 절차에 따라 이의 신청을 할 수 있으며, '회사'는
              정당하다고 인정하는 경우 즉시 서비스 이용 재개 등을 합니다. <br /> • ⑨ '회사'는 '회원'에게 ‘구독취소’를
              이유로 위약금 또는 손해배상을 청구하지 않으며, '회원'의 계약해제∙해지는 손해배상의 청구에 영향을 미치지
              않습니다. 다만, ‘회사’는 ‘회원’이 이용기간을 약정한 상품에 대한 해지에 대해서는 위약금을 청구할 수
              있습니다. <br />• ⑩ ‘유료서비스’를 유지하고자 하는 ‘유료회원’은 이용요금의 체납 또는 결제수단의 연체가
              발생하지 않도록 사전에 조치하여야 합니다. <br /> • ⑪ ‘회원’이 전항을 위반하여 ‘유료서비스’의 신청 또는
              동의에 따라 월 정기결제 중인 ‘유료서비스’를 이용하는 중에 ‘유료서비스’의 이용요금을 체납하는 경우 연체가
              발생한 날 자동으로 이용권 해지가 될 수 있습니다. <br /> • ⑫ ‘유료서비스’를 이용 중인 ‘회원’이 회원가입
              계약을 해지하는 경우 해당 ‘유료서비스’는 즉시 해지되며, '회사'는 '회원’의 서비스 이용한 일 수, 이용내역,
              배송비, 서비스 또는 상품을 통하여 얻은 이익에 해당하는 금액, 할인 받은 금액 등을 차감 후 환불합니다.
              ‘회사’는 이 경우 ‘유료서비스’를 이용한 ‘회원’의 정보와 이용 내용은 관련 법령이 정하는 경우를 제외하고는 이
              약관에 따라 처리합니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제27조 [대금의 지급 및 과오금의 환급]</strong>
            </li>
            <li>
              • ① ‘회원’은 ‘회사’가 제시한 결제방법에 따라 ‘콘텐츠’의 대금을 지급하여야 합니다. <br /> • ② ‘회원’이
              대금을 결제함에 있어서 과오금을 지급한 경우 ‘회사’는 대금결제와 동일한 방법으로 과오금된 전액을 환급하여야
              합니다. 다만, ‘회사’는 동일한 방법으로 과오금의 환급이 불가능할 때는 즉시 이를 ‘회원’에게 고지하고,
              ‘회원’이 선택한 방법으로 환급하여야 합니다. <br /> • ③ ‘회원’의 책임 있는 사유로 과오금이 발생한 경우,
              ‘회사’는 과오금을 환급하는데 소요되는 비용을 합리적인 범위내에서 공제하고 환급할 수 있습니다. <br /> • ④
              ‘회사’는 ‘회원’의 과오금 환급청구를 거절할 경우 과오금이 없음을 입증하여야 합니다. <br /> • ⑤ ‘회사’는
              과오금의 세부 환불절차 및 기타 사항에 대하여 다음 각 호에 정하는 바에 따릅니다. <br /> • 1. ‘회사’나
              ‘회원’이 과오금의 발생사실을 안 때에는 전자우편, 서비스 홈페이지 등 회사가 제시한 방법에 따라 상대방에게
              통보 <br /> • 2. ‘회사’는 ‘회원’에게 환불에 필요한 정보를 요청(회원 성명, 결제증빙서류, 전화번호, 환불
              요청 계좌 등) <br />• 3. ‘회원’은 환불에 필요한 제2호의 정보를 ‘회사’에 제공 <br />• 4. ‘회사’는 ‘회원’의
              정보 제공일로부터 7일 이내 환불 처리(‘회원’의 명시적 의사표시가 있는 경우 차회 요금에서 상계)
            </li>
          </ul>
          <ul className="leading-7">
            <li> 제5장 책임제한 및 분쟁해결에 관한 보칙 제28조 [손해배상]</li>
            <li>
              • ① ‘회사’는 서비스의 결함에 의하여 ‘회사’가 제공하는 ‘유료서비스’의 내용인 ‘콘텐츠’가 손상, 훼손,
              삭제되어 서비스 이용에 실제 발생한 손해만을 배상합니다. 다만, ‘회사’ 또는 ‘회사’ 소속 임직원의 고의 또는
              과실 없이 ‘회원’에게 발생한 일체의 손해에 대하여는 책임을 지지 아니합니다. <br /> • ② 이 약관에서 명시되지
              아니한 ‘회사’의 귀책사유로 인하여 ‘유료서비스’의 이용 ‘회원’에게 손해가 발생한 경우 ‘회사’는 “콘텐츠이용자
              보호지침” 등 관련법령의 규정 및 기타 상관례에 의하여 배상책임을 부담합니다. <br /> • ③ '회원'이 본 약관을
              위반함으로 인하여 '회사'에 손해가 발생하게 되는 경우, '회원'은 '회사'에 대하여 그 손해에 대하여 배상할
              책임이 있습니다. <br />• ④ '회원'의 과실로 이 약관의 이용 제한 관련 각 규정에 근거, 서비스의 이용이
              제한되거나 이용계약이 해지된 경우, ‘회원’이 보유한 모든 ‘디지털 콘텐츠’의 사용권은 상실되며 ‘회사’는 해당
              ‘디지털 콘텐츠’ 구매대금을 반환할 의무를 부담하지 아니합니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong>제29조 [책임 제한]</strong>
            </li>
            <li>
              • ① ‘회사’는 약관, 서비스 이용 방법 및 이용 기준을 준수하지 않는 등 ‘회원’의 귀책사유로 인한 서비스 이용의
              장애에 대한 책임을 지지 않습니다. 다만, 해당 서비스 이용장애에 ‘회사’에도 책임이 있는 경우, 그러하지
              아니합니다. <br /> • ② ‘회사’는 ‘회원’간 또는 ‘회원’과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한
              경우에는 책임이 면제됩니다. 단, ‘회사’가 그러한 거래 사실을 알게 되고 그 거래에 불법적인 행위가 있어
              ‘회원’이 손해를 보게 될 것임을 알고도 ‘회원’에 대하여 상당한 기간 내에 그 사실의 고지 등을 하지 않은 경우,
              그러하지 아니합니다. <br />• ③ ‘회사’는 무료로 제공되는 서비스의 일부 또는 전부를 ‘회사’의 정책, 운영상의
              긴요한 사유로 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법령에 별도 규정이 있지 아니하는 한 별도의
              보상을 하지 않습니다. <br />• ④ '회사'는 다음 각 호의 사유로 인하여 ‘회원’ 또는 제3자에게 발생한 손해에
              대하여는 그 책임을 지지 아니합니다. <br /> • 1. 천재지변, 전쟁, 기간통신사업자의 서비스 중지 등 해결이
              곤란한 기술적 결함 기타 불가항력으로 인해 ‘유료서비스’를 제공할 수 없는 경우 <br /> • 2. ‘회원’이 자신의
              아이디 또는 비밀번호 등의 관리를 소홀히 한 경우 <br /> • 3. ‘회사’의 관리영역이 아닌 공중통신선로의 장애로
              서비스 이용이 불가능한 경우 <br /> • 4. 기타 ‘회사’의 귀책사유가 없는 통신서비스 등의 장애로 인한 경우{' '}
              <br /> • ⑤ '회사'는 ‘회원’ 또는 제3자가 서비스 내 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의
              내용에 대해 보증하지 않으며, ‘회사’ 또는 ‘회사’ 소속 임직원의 고의 또는 중대한 과실이 없는 한 책임이
              면제됩니다. 제29조 [책임 제한] • ⑥ '회원'은 자신의 결정에 의하여 ‘회사’의 서비스를 사용하여 특정
              프로그램이나 정보 등을 다운받거나 접근함으로써 입게 되는 컴퓨터 시스템상의 손해나 데이터, 정보의 상실에
              대한 책임을 집니다. <br /> • ⑦ '회사'는 '회원' 상호간 또는 '회원'과 제3자 상호 간에 서비스를 매개로 발생한
              분쟁에 대해 개입할 의무가 없습니다. 이로 인한 손해가 발생한 경우 ‘회사’의 고의 또는 중대한 과실이 없는 한
              책임이 면제됩니다. <br /> • ⑧ '회사'는 '회원'이 서비스를 이용하여 기대하는 효용을 얻지 못한 것에 대하여
              책임을 지지 않으며 서비스에 대한 취사 선택 또는 이용으로 발생하는 손해 등에 대해서도 책임이 면제됩니다.
            </li>
          </ul>
          <ul className="leading-7">
            <li>
              <strong> 제30조 [관할법원]</strong>
            </li>
            <li>
              • ① 서비스 이용과 관련하여 ‘회사’와 ‘회원’ 사이에 분쟁이 발생한 경우, ‘회사’와 ‘회원’은 분쟁의 해결을 위해
              성실히 협의하고, 협의가 안될 경우 “콘텐츠산업진흥법” 상 콘텐츠분쟁 조정 위원회에 분쟁조정을 신청할 수
              있습니다. <br /> • ② 전항에 따라 분쟁이 해결되지 않을 경우 양 당사자는 소를 제기할 수 있으며, ‘회사’와
              ‘회원’ 간의 소의 관할은 제소 당시의 '회원' 주소 또는 ‘회사’의 본점 소재지에 의하고 주소 또는 거소가
              분명하지 않은 경우에는 민사소송법에 따라 관할법원을 정합니다. <br />
              [부칙]
            </li>
          </ul>
          <p>
            <strong>1. 개정된 현행 약관은 2024년 08월 09일자부터 시행하며, 종전의 약관을 대체합니다.</strong>
          </p>
        </div>
      </main>
    );
  }
}
