import { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ThirdPartyContext } from '../../contexts/ThirdPartyContext';
import { SurveySlider } from './SurveySlider';
import { AIRecommendedTracks, AISurveyResultResponse } from '../../types';
import { AxiosResponse } from 'axios';
import AuthAxios from '../../axios';
import { useTranslation } from 'react-i18next';

export const SoundRecommendations = () => {
  /** Initialization */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: data, pathname }: { state: AISurveyResultResponse; pathname: string } = useLocation();
  const [searchParams] = useSearchParams();

  const { isAuthentication } = useContext(AuthStateContext);
  const { state: thirdPartyState, setState: setThirdPartyState } = useContext(ThirdPartyContext);

  const [tracks, setTracks] = useState<AIRecommendedTracks[]>([]);
  const [isSurveyDone, setIsSurveyDone] = useState(false);

  useEffect(() => {
    setThirdPartyState({
      ...thirdPartyState,
      thirdPartyCode: 'DBLIFE',
      isVip: !!thirdPartyState.isVip ? thirdPartyState.isVip : !!searchParams.get('vip'),
    });
  }, []);

  useEffect(() => {
    async function fetchRecommendedTracks() {
      const response: AxiosResponse<AISurveyResultResponse> = await AuthAxios.post(
        `${process.env.REACT_APP_ENDPOINT}/survey/result`
      );
      setTracks(response.data.recommendedTracks);
    }

    if (!data) {
      fetchRecommendedTracks();
    } else {
      setTracks(data.recommendedTracks);
      setIsSurveyDone(true);
    }
  }, [data]);

  /** Handlers */
  const handleGoNextButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    if (!isAuthentication) {
      return sendUserToSignup();
    }

    setThirdPartyState({
      ...thirdPartyState,
      thirdPartyCode: 'DBLIFE',
      isSurveyFinished: true,
    });
    return navigate('/app/home');
  };

  const sendUserToSignup = () => {
    if (!data) {
      setThirdPartyState({
        ...thirdPartyState,
        thirdPartyCode: 'DBLIFE',
        isSurveyFinished: true,
      });
    } else {
      setThirdPartyState({
        ...thirdPartyState,
        thirdPartyCode: 'DBLIFE',
        isSurveyFinished: false,
        surveyData: {
          surveyResult: data,
          referer: pathname,
        },
      });
    }

    return navigate('/ai-survey/signin', {
      state: {
        referer: pathname,
      },
    });
  };

  return (
    <div className="flex flex-col justify-center w-full h-full bg-gray-400">
      <div className="flex relative flex-col align-center w-full md:w-[800px] h-full m-auto bg-white">
        {tracks && (
          <SurveySlider tracks={tracks} handleUnauthenticatedUser={sendUserToSignup} isSurveyDone={isSurveyDone} />
        )}
        <button
          style={{ background: '#2166eb', color: '#f4f4f4' }}
          className="w-[85%] md:w-[55%] h-[3.125rem] mx-auto text-xl font-bold rounded-lg my-5 md:mt-10"
          onClick={handleGoNextButtonClick}
        >
          {t('survey.moreSounds')}
        </button>
      </div>
    </div>
  );
};
