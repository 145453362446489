import { FormEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { GiftSvg, SOUNDPILLICONSVG } from '../../assets';
import AuthAxios from '../../axios';
import IconButton from '../../components/IconButton';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ModalContext } from '../../contexts/ModalContext';
import { Product } from '../../types';
import { transLn } from '../../utils/transLn';
import { v4 as uuidv4 } from 'uuid';

import { FailRegistCard, SuccessRegistCard } from '../../components/modals/RegistCardModal';

declare const window: typeof globalThis & {
  IMP: any;
};

/** 카드 미 등록시 카드 등록 후 결제 진행.
 * 카드 등록시 기존 로직으로 결제 진행.
 */

export default function GiftPaymentPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location;
  const { IMP } = window;
  // IMP.init(process.env.REACT_APP_IMP_CODE);
  const gift = state?.gift as Product;
  const navigate = useNavigate();
  const { handleModal } = useContext(ModalContext);
  const { user } = useContext(AuthStateContext);
  // 2023.12.20 임시 수정
  const [isCard, setIsCard] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClickIcon = () => {
    navigate('/app/home', { replace: true });
  };
  const handlePostPayment = (gift: Product, res: any) => {
    setLoading(true);
    AuthAxios.post(`/port-one/gift`, res)
      .then((resolve) => {
        navigate('/app/gift/history', { replace: true });
        handleModal(<SuccessGiftPaymentModal info={resolve.data} />);
        //선물권 구매 성공.
        // 모달 창 띄우기
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRegistCard = () => {
    const { IMP } = window;
    IMP.init(process.env.REACT_APP_IMP_CODE);
    const customerUID = uuidv4();
    IMP.request_pay(
      {
        pg: 'danal_tpay.A010014468', // 실제 계약 후에는 실제 상점아이디로 변경
        pay_method: 'card', // 'card'만 지원됩니다.
        amount: 0, // 결제창에 표시될 금액. 실제 승인이 이루어지지는 않습니다. (모바일에서는 가격이 표시되지 않음)
        customer_uid: customerUID, // 필수 입력.
        name: '최초인증결제',
        merchant_uid: 'order_monthly_' + customerUID, // 상점에서 관리하는 주문 번호
      },
      function (rsp: any) {
        // console.log("카드 등록 결과 rsp::", rsp);
        if (rsp.success) {
          // 상품 구매 성공.
          // console.log("다날 카드 등록까지는 성공함.");
          // console.log(rsp);
          // 카드 등록 api.
          AuthAxios.put(`/paymentMethod/card2`, {
            customerUid: customerUID,
          })
            .then((resolve) => {
              console.log('카드 등록 성공');
              console.log(resolve.data);
              setIsCard(true);
              handleModal(<SuccessRegistCard successMessage={rsp.card_name ?? ''} />);
            })
            .catch((error) => {
              // console.log("카드 등록 실패");
              handleModal(<FailRegistCard errorMessage={error.response.data.message ?? ''} />);
            });
        } else {
          // 카드 등록 실패.
          // console.log("카드 등록 실패.");
          // console.log(rsp);
          handleModal(<FailRegistCard errorMessage={rsp.error_msg ?? ''} />);
        }
      }
    );
  };

  const onClickpostPaypalPayment = async (gift: Product) => {
    //const customerUID = uuidv4();
    console.log('onClickpostPaypalPayment');

    const customerUID = uuidv4();
    IMP.init(process.env.REACT_APP_IMP_CODE);
    IMP.loadUI(
      // "paypal-rt",
      'paypal-spb',
      {
        pg: 'paypal_v2.LU2S3GXCDJ6TJ', // 실제 계약 후에는 실제 상점아이디로 변경 paypal_v2.UFYSG9T7RFW2A 7WBB3CKT63FRG //운영 LU2S3GXCDJ6TJ
        pay_method: 'paypal', // 'card'만 지원됩니다.
        amount: gift.price, // 결제창에 표시될 금액.
        //customer_uid: customerUID, // 필수 입력.
        name: '페이팔 결제 수단 등록',

        //merchant_uid: "order_monthly_" + customerUID, // 상점에서 관리하는 주문 번호
      },
      function (rsp: any) {
        console.log('페이팔 결과:: ', rsp);
        if (rsp.error_code) {
          console.log(rsp.error_msg);
        } else {
          AuthAxios.put(`/paymentMethod/card2`, {
            customerUid: rsp.customer_uid,
          })
            .then((resolve) => {
              console.log('카드 등록 성공', resolve);
              // setPaymentMethod({
              //   isCard: true,
              //   cardName: "페이팔",
              //   customerUid: rsp.customer_uid,
              // });
            })
            .catch((error) => {
              console.log('카드등록 실패');
            });
        }
      }
    );
  };

  const onClickpostPayment = (gift: Product) => {
    console.log('onClickpostPayment');
    // let data = {id: 936, code: 'GF8BFYE', orderNumber: 17711713402151408}
    // handleModal(<SuccessGiftPaymentModal info= {data} />);
    //const { IMP } = window;
    IMP.init(process.env.REACT_APP_IMP_CODE);
    console.log('mid:', process.env.REACT_APP_IMP_MID);

    //IMP.init('imp57541487')
    //let priceText = "₩" + (gift.price || 0).toLocaleString()

    const merchant_uid = uuidv4();
    IMP.request_pay(
      {
        pg: `danal_tpay.${process.env.REACT_APP_IMP_MID}`,
        pay_method: 'card',
        name: transLn(gift.description), //"구독권",
        merchant_uid, // 상점에서 관리하는 주문 번호
        amount: gift.price,
        buyer_email: user?.email,
        buyer_name: user?.nickName,
        custom_data: {
          userId: user?.id,
          productId: gift.id,
        },
      },
      function (rsp: any) {
        // callback
        if (rsp.success) {
          console.log(rsp);
          handlePostPayment(gift, rsp);
        } else {
          console.log('failed:', rsp);
        }
        //if (rsp.success) {
        //       // console.log(rsp);
        //console.log(rsp);
        //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
      }
    );

    // IMP.request_pay(
    //   {
    //     pg: "danal_tpay.A010002002", // 실제 계약 후에는 실제 상점아이디로 변경
    //     pay_method: "card", // 'card'만 지원됩니다.
    //     amount: 20000, // 결제창에 표시될 금액. 실제 승인이 이루어지지는 않습니다. (모바일에서는 가격이 표시되지 않음)
    //     customer_uid: customerUID, // 필수 입력.
    //     name: "구독권",
    //     merchant_uid: "order_monthly_" + customerUID, // 상점에서 관리하는 주문 번호
    //     // buyer_email: "iamport@siot.do",
    //     // buyer_name: "아임포트",
    //     // buyer_tel: "02-1234-1234",
    //     // m_redirect_url: process.env.REACT_APP_IMP_M_REDIRECT_URL, // 예: https://www.my-service.com/payments/complete/mobile
    //   },
    //   function (rsp: any) {
    //     // callback
    //     // console.log(rsp);
    //     if (rsp.success) {
    //       // console.log(rsp);

    //       AuthAxios.put(`/paymentMethod/card2`, {
    //         customerUid: customerUID,
    //       })
    //         .then((resolve) => {
    //           console.log("카드 등록 성공");
    //           // setPaymentMethod({
    //           //   isCard: true,
    //           //   cardName: rsp.card_name,
    //           //   customerUid: customerUID,
    //           // });
    //         })
    //         .catch((error) => {
    //           console.log("카드등록 실패");
    //         });
    //     } else {
    //       // 빌링키 발급 실패
    //     }
    //  }
    //);
    //alert("onClickpostPayment")
    /** 2023.12.20 임시 수정 */
    // if (isCard) {
    //   // handleModal(<CheckPasswordModal callback={handlePostPayment} />);
    //   handlePostPayment();
    // } else {
    //   handleModal(
    //     <div className="p-2 flex flex-col items-center bg-N10 text-N0 w-[300px] rounded-lg">
    //       <p>{t("paymentMembershipPage.cardRegistrationRequired")}</p>
    //       <p>{t("paymentMembershipPage.pleaseRegisterYourCard")}</p>
    //       <button
    //         className="w-full h-12 text-base rounded-lg bg-N7 text-N2"
    //         onClick={() => {
    //           handleRegistCard();
    //           handleModal();
    //         }}
    //       >
    //         {t("paymentMembershipPage.cardRegistration")}
    //       </button>
    //     </div>
    //   );
    // }
  };

  useEffect(() => {
    /** 2023.12.20 임시 수정
     * 로그인 되어 있는지 확인 필요.
     * 로그인 안돼어 있으면 해당 페이지에서 벗어나기.
     */
    if (gift) {
      // 현재 카드가 등록되어 있는지 확인 필요.
      AuthAxios.get(`/paymentMethod/card`)
        .then((resolve) => {
          setIsCard(resolve.data.isCard);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  // console.log(gift);
  if (!gift) {
    return <div>not fount product</div>;
  }

  return (
    <div className="flex flex-col w-full h-full bg-N0 px-4 py-4 md:py-[1.6875rem] md:px-[2.625rem] overflow-auto max-md:no-scrollbar">
      <div className="mb-[1rem]">
        <IconButton onClick={onClickIcon}>
          <SOUNDPILLICONSVG />
        </IconButton>
      </div>
      <div className="flex justify-center w-full h-full mt-[2rem] md:mt-0">
        <div className="flex flex-col items-center w-full md:w-[34.6875rem] h-full">
          <h1 className="text:md md:text-[1.375rem] font-medium mb-[1.3125rem] md:mb-[2.75rem]">
            {t('giftPaymentPage.sendPremiumAsGift')}
          </h1>
          <div className="flex justify-center w-full">
            <div className="w-[5.125rem] h-[5.125rem] rounded-[1.25rem] bg-N5 flex justify-center items-center p-[1rem]">
              <GiftSvg className="w-full h-full" />
            </div>
          </div>

          <div className="flex flex-col items-center w-full my-[1.125rem] md:my-[1.875rem] text-sm md:text-md">
            <p>{t('giftPaymentPage.accountForGift')}</p>
            <p className="underline text-N6">{user?.email}</p>
          </div>
          <div className="flex flex-col items-start w-full gap-[10px] md:gap-4 mt-[2rem] md:mt-0">
            <p className="text-bold text-sm md:text-md">{t('giftPaymentPage.orderSummary')}</p>
            <p className="flex justify-between w-full text-sm md:text-md">
              <p>{transLn(gift.description)}</p>
              <p>₩{(gift.price || 0).toLocaleString()}</p>
            </p>

            <div className="flex justify-between w-full py-4 border-y border-N10">
              <span>
                <strong>{t('giftPaymentPage.totalAmount')}</strong>
              </span>
              <span>₩{(gift.price || 0).toLocaleString()}</span>
            </div>
            <div className="flex flex-col w-full gap-[1.3125rem] md:gap-[1.875rem] mt-[1.875rem] md:mt-12">
              <div className="portone-ui-container" data-portone-ui-type="paypal-spb"></div>
              <button
                disabled={loading}
                onClick={() => onClickpostPaypalPayment(gift)}
                className="w-full rounded-lg bg-[#6D6AF6] text-N0 text-lg font-semibold py-4"
              >
                {t('giftPaymentPage.payForGiftCertificate')}
              </button>
              <button
                disabled={loading}
                onClick={() => onClickpostPayment(gift)}
                className="w-full rounded-lg bg-[#712DE0] text-N0 text-lg font-semibold py-4"
              >
                {t('giftPaymentPage.payWithKoreanCard')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="h-auto flex flex-col items-center text-sm text-[#8F8F8F] mt-[4rem] md:mt-[6rem] mb-[1.3125rem] md:my-[1.875rem]">
        <p className="mb-[1.125rem]">{t('giftPaymentPage.secureTransactions')}</p>
        <p>{t('giftPaymentPage.giftCertificateBenefits')}</p>
        <p>{t('giftPaymentPage.noAutomaticRenewal')}</p>
        <p>{t('giftPaymentPage.checkGiftDetails')}</p>
      </div>
    </div>
  );
}
interface ICheckPasswordModalProps {
  callback: Function;
}
function CheckPasswordModal(props: ICheckPasswordModalProps) {
  const { callback } = props;
  const { handleModal } = useContext(ModalContext);

  const onClickSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    callback();
    handleModal();
  };
  return (
    <div className="w-[328px] rounded-lg bg-N10 text-N0 flex flex-col items-center justify-center p-4">
      <div className="text-sm ">결제 후 30일 이내의 </div>
      <div className="mb-4 text-sm">미사용 선물권만 환불이 가능합니다.</div>
      <form onSubmit={onClickSubmit} className="w-full">
        <button type="submit" className="w-full h-12 rounded-lg bg-N7 ">
          결제하기
        </button>
      </form>
    </div>
  );
}

function SuccessGiftPaymentModal(info: any) {
  const { handleModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const onClickClose = () => {
    handleModal();
  };

  const handleUseCoupon = async (info: any) => {
    handleModal();

    // Prepare the text to be shared
    const shareData = {
      title: '', //transLn(info.name),
      text: `+사운드필 선물권+
       GiftCode : ${info.info.code}`,
      url: `https://soundpill.app/app/setting/coupon?giftcode=${info.info.code}`,
    };

    // Check if Web Share is supported
    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        console.error('Error sharing coupon details:', error);
      }
    } else {
      // Fallback: Copy the code to the clipboard
      navigator.clipboard
        .writeText(info.code)
        .then(() => {
          alert('코드번호 클립보드에 복사됨');
        })
        .catch((error) => {
          console.error('Error copying coupon code:', error);
        });
    }
  };

  return (
    <div className="w-[328px] rounded-lg bg-N10 text-N0 flex flex-col p-4 items-center">
      <div className=" text-[22px] font-bold">결제 완료</div>
      <div className="mb-4">결제가 성공적으로 완료되었습니다.</div>
      <div className="mb-4">쿠폰번호</div>
      <div className="mb-4">
        <span className="text-N8">{info.info.code}</span>
      </div>

      <button
        onClick={() => handleUseCoupon(info)}
        className="w-[287px] h-[48px] mb-4 rounded-lg bg-N9 text-N2 text-base"
      >
        공유하기
      </button>
      <button className="w-[287px] h-[48px] rounded bg-N5 text-N9" onClick={() => onClickClose()}>
        확인
      </button>
    </div>
  );
}
