import axios, { InternalAxiosRequestConfig } from 'axios';
import { getToken, removeItem, setItem, USERINFO, USERTOKEN } from '../utils/localStorage';
import { redirect } from 'react-router-dom';

const AuthAxios = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

AuthAxios.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
  const user = getToken(USERTOKEN);
  if (!user) {
    config.headers['accessToken'] = null;
    config.headers['refreshToken'] = null;
    return config;
  }
  const { accessToken, refreshToken } = user;
  config.headers['accessToken'] = accessToken;
  config.headers['refreshToken'] = refreshToken;
  config.headers!.Authorization = `Bearer ${accessToken}`;
  return config;
});

function removeAll() {
  removeItem(USERTOKEN);
  removeItem(USERINFO);
}

AuthAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      try {
        if (error.response.data.message === '재가입은 탈퇴 3개월 이후부터 가능합니다.') {
          return Promise.reject(error);
        }
        const originalRequest = error.config;
        const user = getToken(USERTOKEN);
        const data = await AuthAxios.post('/auth/refresh', user);
        if (data) {
          const { accessToken, refreshToken } = data.data;
          setItem(USERTOKEN, data.data);
          originalRequest.headers['accessToken'] = accessToken;
          originalRequest.headers['refreshToken'] = refreshToken;
          originalRequest.headers!.Authorization = `Bearer ${accessToken}`;
          return await AuthAxios.request(originalRequest);
        }
      } catch (error) {
        removeItem(USERTOKEN);
        removeItem(USERINFO);
        return redirect('/app/home');
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default AuthAxios;
