import { lazy, Suspense, useContext, useState } from 'react';
import { Navigate as NavigateRoute, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { BgBtnSvg, LOGOSVG, MenuMobileSVG } from '../assets';
import AudioPlayer from '../components/audioPlayer/Player.v2';
import IconButton from '../components/IconButton';
import MobileNavigate from '../components/nav/MobileNav';
import Navigate from '../components/Navigate';
import { MusicPlayerStateContext } from '../contexts/MusicContext';
import { EDITOR_Z_IDX } from '../utils/constant';
import loginImg from '../assets/images/unsplash_KonWFWUaAuk.jpg';
import OpenAppBtn from '../components/common/OpenAppBtn';
import CouponPage from '../pages/Promotion/CouponPage';

const HomeRoot = lazy(() => import('../pages/Home/Root'));
const SearchRoot = lazy(() => import('../pages/Search/Root'));
const PlayerPage = lazy(() => import('../pages/Player/index'));
const ProfilePage = lazy(() => import('../pages/Profile/Root'));
const CategoryRoot = lazy(() => import('../pages/Category/Root'));
const PrivatePolicyPage = lazy(() => import('../pages/Consent/PrivatePolicyPage'));
const SettingRoot = lazy(() => import('../pages/Setting/Root'));
const TermsOfUsePage = lazy(() => import('../pages/Consent/TermsOfUsePage'));
const MarketingInformationPage = lazy(() => import('../pages/Consent/MarketingInforamtionPage'));

const PopularClassPage = lazy(() => import('../pages/Class/Popular'));
const RecentClassPage = lazy(() => import('../pages/Class/Recent'));

const MembershipPage = lazy(() => import('../pages/Consent/MembershipPage'));
const GiftRoot = lazy(() => import('../pages/Gift/Root'));
const PaymentResult = lazy(() => import('../pages/PaymentResult'));

export default function AppRoute() {
  const navigate = useNavigate();
  const { currentPlayMusic, currentTheme } = useContext(MusicPlayerStateContext);
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const onClickMenu = () => {
    setMenuOpen(false);
  };

  const noHeader = location.pathname.startsWith('/app/membership');
  return (
    <div className="relative flex w-full h-full">
      <img
        src={currentTheme?.artwork ? `${process.env.REACT_APP_IMG_ENDPOINT}/${currentTheme.artwork}` : loginImg}
        style={{ zIndex: -1 }}
        alt="background_theme"
        className="absolute top-0 left-0 object-cover w-full h-full"
      />
      <div className="absolute top-0 left-0  bg-black bg-opacity-40 w-full h-full"></div>
      <Navigate />

      <main className="relative w-full h-full md:w-[calc(100%-240px)] overflow-hidden">
        {!noHeader && (
          <header className="px-4 flex justify-between items-center top-0 w-full h-[60px] gap-2 md:hidden ">
            <div className="flex items-center gap-2">
              <LOGOSVG className="w-[93px]" style={{ fill: 'white' }} />
              <IconButton
                onClick={() => {
                  navigate('/app/home/backgroundtheme');
                }}
                className="w-[30px] h-[30px] rounded-full bg-N9 flex items-center justify-center"
              >
                <BgBtnSvg />
              </IconButton>
            </div>
            <div className="flex gap-2">
              <OpenAppBtn />
              <IconButton onClick={() => setMenuOpen((prev) => !prev)}>
                <MenuMobileSVG />
              </IconButton>
            </div>
          </header>
        )}
        <main
          className={`${
            noHeader
              ? 'h-full md:h-[calc(100%-80px)] overscroll-y-auto no-scrollbar'
              : currentPlayMusic
                ? 'h-[calc(100%-140px)] md:h-[calc(100%-80px)] overscroll-y-auto no-scrollbar'
                : 'h-[calc(100%-60px)] md:h-full overscroll-y-auto no-scrollbar '
          }`}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="home/*" element={<HomeRoot />} />
              <Route path="search/*" element={<SearchRoot />} />
              <Route path="category/:id/*" element={<CategoryRoot />} />
              <Route path="profile/*" element={<ProfilePage />} />
              <Route path="class/recent-list" element={<RecentClassPage />} />
              <Route path="class/popular-list" element={<PopularClassPage />} />
              <Route path="player/:id" element={<PlayerPage />} />
              <Route path="setting/*" element={<SettingRoot />} />
              <Route path="terms-of-use" element={<TermsOfUsePage />} />
              <Route path="privacy-policy" element={<PrivatePolicyPage />} />
              <Route path="marketing-information" element={<MarketingInformationPage />} />
              <Route path="gift/*" element={<GiftRoot />} />
              <Route path="membership" element={<MembershipPage />} />
              <Route path="payment/result" element={<PaymentResult />} />
              <Route path="promotion/coupon" element={<CouponPage />} />
              <Route path="*" element={<NavigateRoute to="home" replace />} />
            </Routes>
          </Suspense>
        </main>
        <AudioPlayer />
      </main>

      {menuOpen ? (
        <aside
          className="fixed w-screen h-full transition-all duration-500 ease-in-out transform translate-x-0 opacity-100 bg-slate-400"
          style={{ zIndex: EDITOR_Z_IDX }}
        >
          <MobileNavigate onClickMenu={onClickMenu} />
        </aside>
      ) : (
        <aside
          className="fixed w-screen h-full transition-all duration-500 ease-in-out transform translate-x-full opacity-0 bg-slate-400"
          style={{ zIndex: EDITOR_Z_IDX }}
        >
          <MobileNavigate onClickMenu={onClickMenu} />
        </aside>
      )}
    </div>
  );
}
