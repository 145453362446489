import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json';
import translationKO from '../locales/ko/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getItem, LocalLanguage, setItem } from '../utils/localStorage';

const resources = {
  en: {
    translation: translationEN,
  },
  ko: {
    translation: translationKO,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: getItem(LocalLanguage),
    fallbackLng: 'en', // 번역 파일에서 찾을 수 없는 경우 기본 언어
    interpolation: {
      escapeValue: false,
    },
  });

setItem(LocalLanguage, i18n.resolvedLanguage);

export default i18n;
